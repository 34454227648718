import { LocaleProvider as LookieroLocaleProvider } from '@lookiero/locale';
import { useStoryteller } from '@lookiero/storyteller';
import { PropTypes } from 'prop-types';
import React from 'react';

export const LocaleProvider = ({ children }) => {
  const { select } = useStoryteller();

  const bind = select('locale', 'en-GB', {
    options: ['es-ES', 'en-GB', 'fr-FR', 'it-IT', 'pt-PT', 'fr-BE'],
  });

  return (
    <LookieroLocaleProvider locale={bind} dictionaries={['user-area-front']}>
      {children}
    </LookieroLocaleProvider>
  );
};

LocaleProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
