import { DISPLAY, FLEX_DIRECTION, SIZE, Text, View } from '@lookiero/aurora';
import PropTypes from 'prop-types';
import React from 'react';

export const Children = ({ text = 'children' }) => (
  <View
    display={DISPLAY.FLEX}
    flexDirection={FLEX_DIRECTION.COLUMN}
    marginLeft={SIZE.M}
    marginRight={SIZE.M}
    flex={SIZE.XS}
  >
    <Text action upperCase>
      {text}
    </Text>
  </View>
);

Children.propTypes = {
  text: PropTypes.string,
};
