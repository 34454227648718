import AsyncStorage from '@react-native-async-storage/async-storage';
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister';
import { QueryClient } from '@tanstack/react-query';
import { persistQueryClient, PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import React from 'react';
const TTL_IN_MILLIS = 15000 * 60; // 15 minutes

export const queryProviderContext = React.createContext<QueryClient | undefined>(undefined);
export const queryClient = new QueryClient();

export const asyncStoragePersister = createAsyncStoragePersister({
  storage: AsyncStorage,
});

persistQueryClient({
  queryClient,
  persister: asyncStoragePersister,
  maxAge: TTL_IN_MILLIS,
});

export const TanStackQueryProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister: asyncStoragePersister }}
      context={queryProviderContext}
    >
      {children}
    </PersistQueryClientProvider>
  );
};
