export type PaymentsErrorParams =
  | {
      code?: string;
      message?: string;
    }
  | Error
  | any;

export class PaymentsError extends Error {
  public code: string;

  constructor(error: PaymentsErrorParams) {
    super(error.message, { cause: error instanceof Error ? error : undefined });
    this.code = error?.code as string;
  }

  public toString() {
    if (this.code) return `[${this.code}] ${this.message} - ${JSON.stringify(this.cause)}`;
    return `${this.message} - ${JSON.stringify(this.cause)}`;
  }

  public getErrorInfo() {
    return {
      code: this.code,
      message: this.message
    }
  }
}
