import { ALIGN, COLOR, LAYOUT, Box, Layout, Text, Theme, useDevice } from '@lookiero/aurora';
import { useLocale } from '@lookiero/locale';
import React from 'react';

import { Engine } from '../../domain/models';
import { getStripeKey, getIOSInAppBrowserBannerHeight } from '../../services/ioc';
import { isWeb } from '../../utils/platform';
import { StripeProvider } from '../StripeProvider';
import { getElementsOptions } from '../StripeProvider/StripeProvider.controller';
import { TEXT } from './CardUpdater.definition';
import { CardUpdaterForm } from './CardUpdater.form';

const theme = Theme.get();

const CardUpdater = ({ engine }: { engine: Engine.STRIPE | Engine.STRIPE_UK }) => {
  const { screen } = useDevice();
  const { translate, locale } = useLocale();

  const boxHeight = screen.height - theme.layoutL - theme.spaceXXXL - getIOSInAppBrowserBannerHeight();

  return (
    <Layout alignItems={ALIGN.START} justifyContent={ALIGN.CENTER} style={{ flex: 1 }} testID="Layout">
      <Box
        size={{ M: '2/3', L: '1/3' }}
        style={{
          flex: 1,
          flexDirection: 'column',
          height: isWeb ? boxHeight : undefined,
        }}
      >
        <Text as="p" color={COLOR.CONTENT} level={1} align={ALIGN.CENTER} marginBottom={LAYOUT.S}>
          {translate(TEXT.UPDATE_TITLE_CARD)}
        </Text>
        {engine && (
          <StripeProvider publishableKey={getStripeKey(engine)} options={getElementsOptions(locale)}>
            <CardUpdaterForm engine={engine} />
          </StripeProvider>
        )}
      </Box>
    </Layout>
  );
};

export { CardUpdater };
