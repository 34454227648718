import { PortalProvider } from '@lookiero/aurora';
import { useStoryteller } from '@lookiero/storyteller';
import React, { useEffect } from 'react';

import { LoadingModal } from '../components/LoadingModal';
import { useController } from '../components/PaymentInstrumentSelect/PaymentInstrumentSelect.controller';
import { LocaleProvider } from './helpers';

export const LoadingModalChapter = () => (
  <LocaleProvider>
    <PortalProvider>
      <LoadingModalComposer />
    </PortalProvider>
  </LocaleProvider>
);

const LoadingModalComposer = () => {
  const { boolean } = useStoryteller();
  const { showLoadingModal, hideLoadingModal } = useController((s) => ({
    showLoadingModal: s.showLoadingModal,
    hideLoadingModal: s.hideLoadingModal,
  }));
  const isVisible = boolean('isVisible', true);

  useEffect(() => {
    isVisible ? showLoadingModal() : hideLoadingModal();
  }, [hideLoadingModal, isVisible, showLoadingModal]);

  return <LoadingModal />;
};

LoadingModalChapter.displayName = 'Loading Modal';
