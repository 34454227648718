import { useEvent } from '@lookiero/event'
import { useState } from "react";

import { CheckoutAPI } from "../../../infrastructure/CheckoutAPI";
import { graphqlClient } from '../../../infrastructure/graphql';
import { LegacyBoxCheckoutRepository as LegacyBoxCheckoutGraphqlRepository } from '../../../infrastructure/graphql/LegacyBoxCheckoutRepository';
import { LegacyBoxCheckoutAPI } from '../../../infrastructure/LegacyBoxCheckoutAPI';
import { LegacyBoxCheckoutRepository } from '../../../infrastructure/LegacyBoxCheckoutRepository';
import { strategyBuilder } from './PaymentFlowStrategy'
import { CheckoutStrategy } from "./strategies/CheckoutStrategy";
import { LegacyBoxCheckoutStrategy } from './strategies/LegacyBoxCheckoutStrategy';
import { callback } from "./utils/callback";


const initialState = {
  loading: false,
  challengeLoading: false,
  challengeVisible: false,
  challengeUrl: '',
  onChallengeLoad: callback<void>(),
  onChallengeClose: callback<'finished' | 'cancelled' | 'timed_out'>()
}
export type PaymentFlowState =
  typeof initialState


export function usePaymentFlow(token: string) {
  const [state, setState] = useState(initialState);
  const { publish: emitGlobalError } = useEvent();
  const buildStrategy =  strategyBuilder({ initialState, setState, emitGlobalError });

  return {
    state,
    strategies: {
      startCheckout: buildStrategy(CheckoutStrategy, {
        checkoutAPI: new CheckoutAPI(token)
      }),
      startLegacyBoxCheckout: buildStrategy(LegacyBoxCheckoutStrategy, {
        legacyBoxCheckoutAPI: new LegacyBoxCheckoutAPI(new LegacyBoxCheckoutGraphqlRepository(graphqlClient(token)), new LegacyBoxCheckoutRepository(token)),
      })
    }
  }
}
