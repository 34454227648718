import { SafeAreaView } from '@lookiero/aurora';
import React from 'react';
import { Text } from 'react-native';

import { getAppVersion, getPaymentsFrontVersion } from '../services/ioc';

export const App = () => {
  const appVersion = getAppVersion();
  const paymentsFrontVersion = getPaymentsFrontVersion();

  return (
    <SafeAreaView>
      <Text>App version: {appVersion}</Text>
      <Text>Payments Front version: {paymentsFrontVersion}</Text>
    </SafeAreaView>
  );
};

App.displayName = 'App';
