import { isWeb } from './platform';

export default function importScript(url: string, onLoad: () => void) {
  if (!isWeb) return;
  const script = document.createElement('script');

  script.src = url;
  script.async = true;

  document.body.appendChild(script);
  script.addEventListener('load', onLoad);

  return () => {
    script.removeEventListener('load', onLoad);
    document.body.removeChild(script);
  };
}
