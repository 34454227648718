import { StripeElementLocale, StripeElementsOptions } from "@stripe/stripe-js";

import { getCommonAssetsPath } from "../../services/ioc";

const getStripeLanguageFromLocale = (locale: string): StripeElementLocale => {
  if (locale === 'en-GB') {
    return locale as StripeElementLocale;
  }

  return locale.split('-')[0] as StripeElementLocale;
};

const getElementsOptions = (locale: string): StripeElementsOptions => ({
  locale: getStripeLanguageFromLocale(locale),
  fonts: [
    {
      family: 'canela',
      src: `url(${getCommonAssetsPath()}/fonts/Canela-Light.woff2)`,
      unicodeRange:
        'U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215',
    },
  ],
});

export { getElementsOptions, getStripeLanguageFromLocale };
