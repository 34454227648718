import { styles, Text, View, Icon, ALIGN, COLOR, POSITION, LAYOUT } from '@lookiero/aurora';
import { useLocale } from '@lookiero/locale';
import React from 'react';

import { getIOSInAppBrowserBannerHeight } from '../../services/ioc';
import { style } from './InAppBrowserBanner.styles';

export const TEXT = {
  BANNER_TEXT: { id: 'myLK.overheader' },
};

export const InAppBrowserBanner = () => {
  const { translate } = useLocale();

  return getIOSInAppBrowserBannerHeight() ? (
    <View
      position={POSITION.FIXED}
      testID="payments-in-app-browser-banner"
      style={styles(style.inAppBrowserBanner, { height: getIOSInAppBrowserBannerHeight() })}
    >
      <Icon name="package" color={COLOR.BASE} marginRight={LAYOUT.XXS} />
      <Text align={ALIGN.CENTER} color={COLOR.BASE} detail level={2} style={style.bannerText}>
        {translate(TEXT.BANNER_TEXT)}
      </Text>
    </View>
  ) : (
    <></>
  );
};
