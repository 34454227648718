import { ALIGN, COLOR, LAYOUT, Header, Panel, Portal } from '@lookiero/aurora';
import React from 'react';
import { ScrollView } from 'react-native';

import { useAnimatedVisibility } from '../../hooks/useAnimatedVisibility';
import { getIOSInAppBrowserBannerHeight } from '../../services/ioc';
import { useRandomString } from '../../utils/randomString';
import { InAppBrowserBanner } from '../InAppBrowserBanner';

type PaymentPanelProps = {
  children: React.ReactNode;
  isVisible: boolean;
  id: string;
  title: string;
  onClose: () => void;
};

export const PaymentPanel = (props: PaymentPanelProps) => {
  // Ugly workaround
  const randomId = useRandomString(5);
  const { isRendered, isVisible } = useAnimatedVisibility(props.isVisible);

  if (!isRendered) return <></>;
  return (
    <Portal id={'payment-panel-' + props.id + '-' + randomId}>
      <Panel
        isVisible={isVisible}
        align={ALIGN.RIGHT}
        color={COLOR.BASE}
        testID={'payment-panel-' + props.id}
        onClose={props.onClose}
      >
        <InAppBrowserBanner />
        <Header
          color={COLOR.BASE}
          onBack={props.onClose}
          title={props.title}
          action
          level={3}
          marginBottom={LAYOUT.S}
          style={{ marginTop: getIOSInAppBrowserBannerHeight() }}
        />
        <ScrollView keyboardShouldPersistTaps="always" contentContainerStyle={{ flexGrow: 1 }}>
          {props.children}
        </ScrollView>
      </Panel>
    </Portal>
  );
};
