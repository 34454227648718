import { useEvent } from '@lookiero/event';
import React, { forwardRef, useImperativeHandle } from 'react';

import { getMessageForDeclineCode } from '../../../../domain/declineCodes';
import { DelayedTokenizationStatus } from '../../../../domain/models';
import { PaymentsError } from '../../../../domain/PaymentsError';
import { usePaymentInstrument } from '../../../../hooks/usePaymentInstrument';
import { emitGlobalError, emitPaymentInstrumentSelectToDelayedTokenization } from '../../../../utils/eventEmitter';
import { getAccordionForPendingPaymentMethod } from '../../accordions';
import { useController } from '../../PaymentInstrumentSelect.controller';
import { TEXT } from './DelayedTokenizer.definition';

export type DelayedTokenizerRef = {
  runDelayedTokenization: () => Promise<void>;
  hasPendingDelayedTokenization: () => boolean;
};

export const DelayedTokenizer = forwardRef(({ priceForTokenization }: { priceForTokenization: number }, ref) => {
  const controller = useController((s) => s);
  const paymentInstrument = usePaymentInstrument();
  const { publish } = useEvent();

  useImperativeHandle(
    ref,
    (): DelayedTokenizerRef => ({
      runDelayedTokenization: async () => {
        const pendingPaymentInstrument = controller.getPendingPaymentInstrument();
        if (!pendingPaymentInstrument) return;
        try {
          const { handler } = getAccordionForPendingPaymentMethod(
            pendingPaymentInstrument.payment_method,
            pendingPaymentInstrument.engine,
          );
          await handler({
            controller,
            engine: pendingPaymentInstrument.engine,
            priceForTokenization: priceForTokenization,
            publish,
          });
          emitPaymentInstrumentSelectToDelayedTokenization(publish, 'DelayedTokenizer', TEXT.NOTIFICATION_SUCCESS, {
            selected: false,
          });
          const refreshPromise = paymentInstrument.refresh();
          controller.removePendingPaymentInstrument();
          await refreshPromise;
        } catch (err) {
          if (err instanceof PaymentsError && err.code === DelayedTokenizationStatus.CANCELED) {
            return Promise.reject(err);
          }
          emitGlobalError(
            publish,
            'PaymentInstrumentSelect',
            {
              toaster: getMessageForDeclineCode((err as PaymentsError)?.code),
            },
            (err as PaymentsError)?.getErrorInfo(),
          );
          Promise.reject();
        }
      },
      hasPendingDelayedTokenization: () => {
        return !!controller.getPendingPaymentInstrument();
      },
    }),
  );

  return <></>;
});
