import ApolloClient, { InMemoryCache } from 'apollo-boost';

import { getGraphqlUri } from '../../services/ioc';
import { getHeaders } from '../headers';

const inMemoryCache = new InMemoryCache({ addTypename: false });
let GraphqlClientStatic: ApolloClient<InMemoryCache> | undefined;

// We can't create the ApolloClient when the module is loaded because React Native changes the fetch function asynchronously
// and Apollo uses fetch inside the Client creation.
export const graphqlClient = (token: string): ApolloClient<InMemoryCache> => {
  if (GraphqlClientStatic !== undefined) {
    return GraphqlClientStatic;
  }

  GraphqlClientStatic = new ApolloClient({
    cache: inMemoryCache,
    version: '0.0.1',
    uri: getGraphqlUri(),
    fetch,
    request: async (operation: {
      setContext: (arg0: {
        headers: { 'App-Version': string; 'Payments-Front-Version': string; Authorization: string };
      }) => void;
    }) => {
      operation.setContext({
        headers: { ...getHeaders(token) },
      });
    },
  });
  return GraphqlClientStatic;
};
