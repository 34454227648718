import { InputField, Button, Link, View, Text, SIZE, ALIGN } from '@lookiero/aurora';
import { useStoryteller } from '@lookiero/storyteller';
import React, { useState, useEffect } from 'react';

import { PaymentsError } from '../domain/PaymentsError';
import { BufferBridge as Buffer } from '../utils/buffer/buffer';
import * as tokenStore from './helpers/tokenStore';

export const TokenSetterChapter = () => {
  const { event } = useStoryteller();
  const [token, setToken] = useState('');

  useEffect(() => {
    (async () => {
      setToken(await tokenStore.getToken());
    })();
  }, []);

  const onResetHandler = async () => {
    await tokenStore.resetToken();
    setToken(await tokenStore.getToken());
    event('Token resetted');
  };

  const onSaveHandler = async () => {
    await tokenStore.setToken(token);
    event('Token updated');
  };

  const getTokenInfo = () => {
    try {
      if (!token) return 'Token is invalid';
      const infoPart = token.split('.')[1] as string;
      return JSON.stringify(JSON.parse(Buffer.from(infoPart, 'base64').toString()), null, 2);
    } catch (err) {
      return 'Error parsing token: ' + (err as PaymentsError).message;
    }
  };

  return (
    <View margin={SIZE.M}>
      <InputField name="token" type="text" value={token} onChange={setToken} label="Access Token" />
      <Link
        align={ALIGN.CENTER}
        marginTop={SIZE.M}
        href="https://testing-data-provider-front.dev.envs.lookiero.tech/admin/users"
        underlined={true}
      >
        Testing Data Provider
      </Link>
      <Button marginTop={SIZE.M} onPress={onResetHandler} variant="secondary">
        Reset token
      </Button>
      <Button marginTop={SIZE.M} onPress={onSaveHandler}>
        Save token
      </Button>
      <Text marginTop={SIZE.M} selectable={true}>
        {getTokenInfo()}
      </Text>
    </View>
  );
};

TokenSetterChapter.displayName = 'Token Setter';
