import gql from 'graphql-tag';

export const USER_SUMMARY = gql`
  query getUserSummary {
    orders {
      services {
        id
        checkout {
          items {
            reference
          }
        }
      }
    }
  }
`;
