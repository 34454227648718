import StyleSheet from 'react-native-extended-stylesheet';

export const style = StyleSheet.create({
  header: {
    borderBottomWidth: '$borderSize',
    borderStyle: '$borderStyle',
  },
  paymentInstrumentLogo: {
    borderRadius: 4,
    alignSelf: 'center',
    marginRight: 8,
  },
  accordionIcon: {
    alignSelf: 'center',
    marginLeft: 8,
  },
});
