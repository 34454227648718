import { ImageSizes } from '../domain/models';
import { getPaymentsAssetsPath } from '../services/ioc';

const FORMAT = 'png';

const SIZES_DENSITIES: { [key in keyof typeof ImageSizes]: number } = {
  [ImageSizes.S]: 1,
  [ImageSizes.M]: 2,
  [ImageSizes.L]: 3,
};

export const getCDNImages = (path: string, defaultSize: ImageSizes) => ({
  src: imageURL(path, defaultSize),
  srcSet: Object.values(ImageSizes)
    .map((size) => `${imageURL(path, size)} ${density(size)}`)
    .join(', '),
});

const imageURL = (path: string, size: ImageSizes) =>
  `${getPaymentsAssetsPath()}/images/${path}@${density(size)}.${FORMAT}`;

const density = (size: ImageSizes) => `${SIZES_DENSITIES[size]}x`;
