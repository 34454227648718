import importScript from './utils/importScript';

export { PaymentsBridge, setPaymentsBridge } from './services/ioc';
export { PaymentsQueryProvider } from './infrastructure/PaymentsQueryProvider';
export * from './components';
export * from './domain/models';
export { usePaymentInstrument, getPaymentInstrument } from './hooks/usePaymentInstrument';
export { PaymentsError } from './domain/PaymentsError';

importScript('https://pay.google.com/gp/p/js/pay.js', window.paymentsFront_onGooglePayLoaded);
