import { PaymentFlowState } from "./PaymentFlowHook";
import { callback } from "./utils/callback";
import { Mutator } from "./utils/mutator";

export class PaymentFlowUI {
  constructor(
    private set: Mutator<PaymentFlowState>) {}

  public async displayChallenge(url: string, timeoutInSeconds: number) {
    await this.challengeLoadingWhile(this.loadChallengeUrl(url))
    this.set("challengeVisible", true);

    const closeCallback = this.set("onChallengeClose", callback())
    const timeout = setTimeout(() => {
      closeCallback.call("timed_out")
    }, timeoutInSeconds * 1000)
    const result = await closeCallback.promise;

    clearTimeout(timeout)
    this.set("challengeUrl", '');
    this.set("challengeVisible", false)

    return result;
  }

  public async loadChallengeUrl(url: string) {
    const challengeLoaded = this.set("onChallengeLoad", callback()).promise
    this.set("challengeUrl", url);
    await challengeLoaded;
  }

  public async loadingWhile<T>(promise: Promise<T>): Promise<T> {
    this.set("loading", true);
    const result = await promise;
    this.set("loading", false);
    return result;
  }

  public async challengeLoadingWhile<T>(promise: Promise<T>): Promise<T> {
    this.set("challengeLoading", true);
    const result = await promise;
    this.set("challengeLoading", false);
    return result;
  }

}
