import { ALIGN, COLOR, SIZE, FLEX_DIRECTION, DISPLAY, Icon, View } from '@lookiero/aurora';
import React, { useContext } from 'react';

import { ImageSizes, PaymentInstrumentBrand, PaymentInstrumentNetwork } from '../../domain/models';
import { PaymentInstrumentLogo, findLogo, findFallbackLogo } from '../PaymentInstrumentLogo';
import { PaymentInstrumentAccordionContext } from '../PaymentInstrumentSelect/accordions/PaymentInstrumentAccordionContext';
import { useController } from '../PaymentInstrumentSelect/PaymentInstrumentSelect.controller';
import { style } from './PaymentAccordion.styles';

type PaymentAccordionProps = {
  children: React.ReactNode;
  paymentInstrumentBrand: PaymentInstrumentBrand;
  paymentInstrumentNetworks?: PaymentInstrumentNetwork[];
  testID: string;
};

export const PaymentAccordion = ({
  children,
  paymentInstrumentBrand,
  paymentInstrumentNetworks,
  testID,
}: PaymentAccordionProps) => {
  const { editable } = useContext(PaymentInstrumentAccordionContext);
  const hasError = useController((s) => s.hasError);
  const color = hasError ? COLOR.PRIMARY : COLOR.CONTENT;
  const iconName = editable ? 'pencil' : 'arrow-right';

  return (
    <View
      alignItems={ALIGN.CENTER}
      borderColor={color}
      display={DISPLAY.FLEX}
      flexDirection={FLEX_DIRECTION.ROW}
      paddingVertical={SIZE.M}
      tabIndex={0}
      testID={testID}
      style={style.accordion}
      wide
    >
      <View marginRight={SIZE.M}>
        <PaymentInstrumentLogo
          logoName={findLogo(paymentInstrumentBrand, paymentInstrumentNetworks)}
          fallbackLogoName={findFallbackLogo(paymentInstrumentBrand)}
          size={ImageSizes.S}
        />
      </View>
      {children}
      <Icon name={iconName} color={color} />
    </View>
  );
};
