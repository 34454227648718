import { ALIGN, COLOR, SIZE, Text, Touchable, View } from '@lookiero/aurora';
import { useEvent } from '@lookiero/event';
import { useLocale } from '@lookiero/locale';
import React from 'react';

import { getMessageForDeclineCode } from '../../../../domain/declineCodes';
import { DelayedTokenizationStatus, Engine, PaymentMethod } from '../../../../domain/models';
import { PaymentsError } from '../../../../domain/PaymentsError';
import { emitTrackingEvent } from '../../../../services/ioc';
import {
  emitGlobalError,
  emitPaymentInstrumentSelectToDelayedTokenization,
  emitPaymentInstrumentUpdated,
} from '../../../../utils/eventEmitter';
import { TEXT as CardUpdaterDefinition } from '../../../CardUpdater/CardUpdater.definition';
import { useGooglePayState } from '../../../GooglePay';
import { findAccordionMap, getAccordion } from '../../accordions';
import { useController } from '../../PaymentInstrumentSelect.controller';
import { TEXT } from '../../PaymentInstrumentSelect.definition';
import usePaymentMethodsAvailability from '../AvailableIntegrations/usePaymentMethodsAvailability';
import { TEXT as DelayedTokenizerDefinition } from '../DelayedTokenizer/DelayedTokenizer.definition';

const PAYMENT_METHOD_ORDER = [PaymentMethod.CARD, PaymentMethod.GOOGLE_PAY, PaymentMethod.PAYPAL];

export const AvailablePaymentMethods = () => {
  const { publish } = useEvent();
  const controller = useController((s) => s);
  const availability = usePaymentMethodsAvailability();

  const getComponentFor = ({
    paymentMethod,
    engine,
  }: {
    paymentMethod: PaymentMethod;
    engine: Engine;
  }): JSX.Element => {
    const { Accordion, handler, delayable } = getAccordion(paymentMethod, engine);
    const onPressHandler = async () => {
      try {
        emitTrackingEvent({
          event: 'paymentSelection',
          paymentMethod: paymentMethod,
          previousPaymentMethod: 'none',
        });
        if (delayable && controller.allowDelayedTokenization) {
          controller.selectPaymentMethodToDelayedTokenization(paymentMethod, engine);
          emitPaymentInstrumentSelectToDelayedTokenization(
            publish,
            AvailablePaymentMethods.name,
            DelayedTokenizerDefinition.NOTIFICATION_SUCCESS,
            { selected: true },
          );
          controller.setShowPaymentInstrumentSelectPanel(false);
          return;
        }
        await handler({ controller, engine, priceForTokenization: 0, publish });
        if (delayable) {
          emitPaymentInstrumentUpdated(
            publish,
            AvailablePaymentMethods.name,
            CardUpdaterDefinition.NOTIFICATION_SUCCESS,
          );
        }
      } catch (err) {
        if (err instanceof PaymentsError && err.code === DelayedTokenizationStatus.CANCELED) return;
        emitGlobalError(publish, Accordion.name, {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          toaster: getMessageForDeclineCode((err as any)?.payload?.code),
        });
      }
    };

    return (
      <Touchable key={paymentMethod} role="button" style={{ width: '100%' }} onPress={onPressHandler}>
        <Accordion engine={engine} />
      </Touchable>
    );
  };

  const getAvailablePaymentMethodsComponents = () =>
    Object.values(PaymentMethod)
      .reduce(
        (result, paymentMethod) => {
          const engine = availability[paymentMethod];
          if (
            engine !== null &&
            PaymentMethod.GOOGLE_PAY === paymentMethod &&
            !useGooglePayState.getState().isAvailable
          )
            return [...result];
          return [
            ...result,
            ...(engine != null && findAccordionMap(paymentMethod, engine) ? [{ paymentMethod, engine }] : []),
          ];
        },
        [] as {
          paymentMethod: PaymentMethod;
          engine: Engine;
        }[],
      )
      .sort(byPaymentMethodOrder)
      .map(getComponentFor);

  const getPayPalDisclaimerComponent = () => {
    const engine = availability[PaymentMethod.PAYPAL];
    return engine != null && findAccordionMap(PaymentMethod.PAYPAL, engine) && <PayPalDisclaimer />;
  };

  return (
    <>
      {getAvailablePaymentMethodsComponents()}
      {controller.hasError && <ErrorBlock />}
      {getPayPalDisclaimerComponent()}
    </>
  );
};

const ErrorBlock = () => {
  const { translate } = useLocale();
  return (
    <View marginTop={SIZE.M}>
      <Text detail level={2} color={COLOR.PRIMARY} align={ALIGN.CENTER}>
        * {translate(TEXT.PAYMENT_METHOD_UNSELECTED_ERROR)}
      </Text>
    </View>
  );
};

const PayPalDisclaimer = () => {
  const { translate } = useLocale();
  return (
    <View marginTop={SIZE.L}>
      <Text detail level={2} color={COLOR.GRAYSCALE_XL} align={ALIGN.CENTER}>
        {translate(TEXT.PAYMENT_METHOD_PAYPAL_DISCLAIMER)}
      </Text>
    </View>
  );
};

const byPaymentMethodOrder = (a: { paymentMethod: PaymentMethod }, b: { paymentMethod: PaymentMethod }) =>
  PAYMENT_METHOD_ORDER.indexOf(a.paymentMethod) - PAYMENT_METHOD_ORDER.indexOf(b.paymentMethod);
