import { ALIGN, DISPLAY, FLEX_DIRECTION, IMAGE_RESIZE_MODES, Image, View } from '@lookiero/aurora';
import React, { useEffect, useState } from 'react';

import { ImageSizes } from '../../domain/models';
import { getCDNImages } from '../../infrastructure/getCDNImages';
import { style } from './PaymentInstrumentLogo.styles';

type PaymentInstrumentLogoProps = {
  logoName: string;
  fallbackLogoName: string;
  size: ImageSizes;
};

export const PaymentInstrumentLogo = ({ logoName, fallbackLogoName, size }: PaymentInstrumentLogoProps) => {
  const [isError, setIsError] = useState(false);
  const [cdnImages, setCdnImages] = useState({ src: '', srcSet: '' });

  const setImages = (logoName: string, size: ImageSizes) => {
    setCdnImages(getCDNImages(logoName, size));
  };

  useEffect(() => {
    setIsError(false);
    setImages(logoName, size);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoName, size]);

  const onError = () => {
    setIsError(true);
    setImages(fallbackLogoName, size);
  };

  if (!cdnImages.src || !cdnImages.srcSet) return <></>;
  return (
    <View alignItems={ALIGN.CENTER} display={DISPLAY.FLEX} flexDirection={FLEX_DIRECTION.ROW}>
      <Image
        autoSize={true}
        resizeMode={IMAGE_RESIZE_MODES[0]}
        src={cdnImages.src}
        srcSet={cdnImages.srcSet}
        style={style.logo}
        onError={!isError ? onError : null}
      />
    </View>
  );
};
