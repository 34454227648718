const PKG = require('./package.json');

module.exports = {
  alias: {
    '@lookiero/aurora': '@lookiero/aurora-next',
    '@tests': './src/__tests__',
    '@e2e': './src/test/e2e/',
  },

  development: {
    devServer: {
      hot: true,
      proxy: {
        '/api': {
          secure: false,
          changeOrigin: false,
          target: 'https://user-proxy.dev.envs.lookiero.tech',
        },
        '/web': {
          secure: false,
          changeOrigin: false,
          target: 'https://user-proxy.dev.envs.lookiero.tech',
        },
        '/bfu': {
          secure: false,
          changeOrigin: false,
          pathRewrite: { '^/bfu': '' },
          target: 'https://web2.dev.aws.lookiero.es',
        },
      },
    },
  },

  name: PKG.name.split('/').pop(),
  version: PKG.version,
};
