export const TEXT = {
  CARD: { id: 'payment.method.card' },
  CREDIT_CARD: { id: 'payment.method.credit_card' },
  GOOGLE_PAY: {id: 'payment.method.GooglePay.title'},
  GOOGLE_PAY_PLACEHOLDER: {id: 'payment.method.googlepay.placeholder'},
  PANEL_CARD_TITLE: { id: 'payment.method.title_card' },
  PANEL_PAYMENT_METHOD_SELECT_TITLE: { id: 'payment.method.title' },
  PANEL_PAYMENT_METHOD_SELECT_BODY: { id: 'payment.method.select' },
  PAYMENT_METHOD: { id: 'payment.method.credit_card' },
  PAYMENT_METHOD_PAYPAL_DISCLAIMER: { id: 'payment.method.paypal.disclaimer' },
  PAYMENT_METHOD_UNSELECTED_ERROR: { id: 'payment.method.unselected.error' },
  PAYPAL: { id: 'payment.method.paypal.title' },
  NOT_FINALIZED: { id: 'payment.notification.not-finalized' },
  NOTIFICATION_SUCCESS: { id: 'payment.notification.paypal_tokenization_success' },
};
