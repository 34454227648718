import { LocalAdapter, AsyncStorage } from '@lookiero/data-sources';

import { AvailableIntegrations, Engine, PaymentMethod, Section } from '../../../../domain/models';
import {
  PaymentInstrumentRepository,
  paymentInstrumentRepository,
} from '../../../../infrastructure/PaymentInstrumentRepository';
import { parseUserToken } from '../../../../utils/parseUserToken';

const HOURS_IN_MILLIS = 60 * 60 * 1000;
const CACHE_TTL = 2 * HOURS_IN_MILLIS;

type AvailableIntegrationsCache = {
  savedDate: number;
  availableIntegrations: AvailableIntegrations;
};

type CustomStorage = {
  has(key: string): Promise<boolean>;
  get<T>(key: string): Promise<T>;
  set<T>(key: string, data: T): Promise<void>;
};

const DEFAULT_INTEGRATIONS: AvailableIntegrations = [{ engine: Engine.STRIPE, availableMethods: [PaymentMethod.CARD] }];

export class AvailableIntegrationsService {
  constructor(private storage: Promise<CustomStorage>, private paymentInstrumentRepository: PaymentInstrumentRepository) {}

  public async get(section: Section, token: string, errEmitter: (err: Error) => void): Promise<AvailableIntegrations> {
    const cacheData = await this.getFromCache(section, token);
    if (cacheData) return cacheData;

    const apiData = await this.getFromAPI(section, token, errEmitter);
    if (apiData) {
      await this.setCache(section, token, apiData);
      return apiData;
    }

    await this.setCache(section, token, DEFAULT_INTEGRATIONS);
    return DEFAULT_INTEGRATIONS;
  }

  private async getFromAPI(
    section: Section,
    token: string,
    errEmitter: (err: Error) => void,
  ): Promise<AvailableIntegrations | null> {
    try {
      return await this.paymentInstrumentRepository
        .getAvailableIntegrations(section, token)
        .then((d) => d.filter(integrationsWithKnownEngines));
    } catch (err: unknown) {
      errEmitter(err as Error);
      return null;
    }
  }

  private async getFromCache(section: Section, token: string): Promise<AvailableIntegrations | null> {
    const key = cacheKey(section, token);
    if (!(await (await this.storage).has(key))) return null;

    const data = await (await this.storage).get<AvailableIntegrationsCache>(key);
    if (cacheIsExpired(data)) return null;

    return data.availableIntegrations;
  }

  private async setCache(section: Section, token: string, data: AvailableIntegrations) {
    await (await this.storage).set<AvailableIntegrationsCache>(cacheKey(section, token), {
      savedDate: new Date().getTime(),
      availableIntegrations: data,
    });
  }
}

const integrationsWithKnownEngines = (i: { engine: Engine }) => Object.values(Engine).includes(i.engine);

const cacheKey = (section: Section, token: string) => `availability-cache-${section}-${parseUserToken(token).uuid}`;

const cacheIsExpired = (cache: AvailableIntegrationsCache) =>
  new Date().getTime() - new Date(cache.savedDate).getTime() > CACHE_TTL;

export const availableIntegrationsService = new AvailableIntegrationsService(
  new AsyncStorage({ adapter: LocalAdapter }),
  paymentInstrumentRepository,
);
