import { Touchable } from '@lookiero/aurora';
import React from 'react';

import { PaymentInstrument, PaymentMethod } from '../../../../domain/models';
import { usePaymentInstrument } from '../../../../hooks/usePaymentInstrument';
import { emitTrackingEvent } from '../../../../services/ioc';
import { getAccordionForPaymentInstrument } from '../../accordions';
import { PaymentInstrumentAccordionContext } from '../../accordions/PaymentInstrumentAccordionContext';
import { useController } from '../../PaymentInstrumentSelect.controller';
import usePaymentMethodsAvailability from '../AvailableIntegrations/usePaymentMethodsAvailability';

export type CurrentPaymentMethodProps = {
  paymentInstrument: PaymentInstrument;
};

export const CurrentPaymentMethod = (props: CurrentPaymentMethodProps) => {
  const paymentInstrument = usePaymentInstrument();
  const controller = useController((s) => s);

  const availability = usePaymentMethodsAvailability();

  const uniquePaymentMethodAvailable =
    !!availability[props.paymentInstrument.payment_method] &&
    Object.keys(availability).filter((key) => availability[key as PaymentMethod]).length === 1;

  const getComponent = (): JSX.Element => {
    const { [props.paymentInstrument.payment_method]: availableEngine } = availability;
    const engine = availableEngine || props.paymentInstrument.engine;
    const { Accordion, handler } = getAccordionForPaymentInstrument(props.paymentInstrument, engine);

    const onPressHandler = () => {
      if (!uniquePaymentMethodAvailable) {
        controller.setShowCardUpdaterPanel(null);
        controller.setShowPaymentInstrumentSelectPanel(true);
        return;
      }
      handler({ controller, engine });
      emitTrackingEvent({
        event: 'paymentSelection',
        paymentMethod: props.paymentInstrument.payment_method,
        previousPaymentMethod: paymentInstrument.info?.payment_method || 'none',
      });
    };

    return (
      <Touchable
        key={props.paymentInstrument.payment_method}
        role="button"
        style={{ width: '100%' }}
        onPress={onPressHandler}
      >
        <PaymentInstrumentAccordionContext.Provider value={{ editable: true }}>
          <Accordion paymentInstrument={props.paymentInstrument} engine={availableEngine} />
        </PaymentInstrumentAccordionContext.Provider>
      </Touchable>
    );
  };

  return <>{getComponent()}</>;
};
