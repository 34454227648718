/**
 * Callback is a generic type that allows creating an structure that contains
 * both the function to be called and the resulting promise, so you can
 * subscribe to the value being received.
 * 
 * const cb = callback<string>()
 * 
 * cb.promise.then((name) => console.log(name))
 * cb.call("Peter")
 * 
 * Outputs: "Peter"
 */

export type Callback<T> = {
  call: (v: T) => void,
  promise: Promise<T>
}

export const callback = <T>(): Callback<T> => {
  let call: (value: T) => void = () => {};
  const promise = new Promise<T>((resolve) => call = resolve);
  return { call, promise };
}
