import { COLOR, DISPLAY, FLEX_DIRECTION, SIZE, Text, View } from '@lookiero/aurora';
import { useLocale } from '@lookiero/locale';
import React from 'react';

import { Engine, PaymentInstrumentBrand, PayPalPaymentInstrument } from '../../../../domain/models';
import { PaymentAccordion } from '../../../PaymentAccordion';
import { TEXT } from '../../PaymentInstrumentSelect.definition';

type PaypalAccordionProps = {
  paymentInstrument?: PayPalPaymentInstrument;
  engine: Engine.STRIPE | Engine.STRIPE_UK | null;
};

export const PaypalAccordion = (props: PaypalAccordionProps) => {
  const { translate } = useLocale();

  return (
    <PaymentAccordion paymentInstrumentBrand={PaymentInstrumentBrand.PAYPAL} testID={'PaypalAccordion'}>
      <View display={DISPLAY.FLEX} flexDirection={FLEX_DIRECTION.COLUMN} marginRight={SIZE.M} flex={SIZE.XS}>
        {props.paymentInstrument != null ? (
          <>
            <Text action upperCase>
              {translate(TEXT.PAYPAL)}
            </Text>
            <Text color={COLOR.GRAYSCALE_XL} ellipsizeMode marginTop={SIZE.S} height={SIZE.XXS}>
              {props.paymentInstrument.metadata.verifiedEmail}
            </Text>
          </>
        ) : (
          <Text detail level={1}>
            {translate(TEXT.PAYPAL)}
          </Text>
        )}
      </View>
    </PaymentAccordion>
  );
};
