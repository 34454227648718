import { PortalProvider } from '@lookiero/aurora';
import { useStoryteller } from '@lookiero/storyteller';
import React, { useEffect } from 'react';

import { useController } from '../components/PaymentInstrumentSelect/PaymentInstrumentSelect.controller';
import { PayPalRedirectionModal } from '../components/PayPalRedirectionModal';
import { LocaleProvider } from './helpers';

export const PayPalRedirectionModalChapter = () => (
  <LocaleProvider>
    <PortalProvider>
      <PayPalRedirectionModalComposer />
    </PortalProvider>
  </LocaleProvider>
);

const PayPalRedirectionModalComposer = () => {
  const { boolean } = useStoryteller();
  const { showPayPalRedirectionModal, hidePayPalRedirectionModal } = useController((s) => ({
    showPayPalRedirectionModal: s.showPayPalRedirectionModal,
    hidePayPalRedirectionModal: s.hidePayPalRedirectionModal,
  }));
  const isVisible = boolean('isVisible', true);

  useEffect(() => {
    isVisible ? showPayPalRedirectionModal() : hidePayPalRedirectionModal();
  }, [hidePayPalRedirectionModal, isVisible, showPayPalRedirectionModal]);

  return <PayPalRedirectionModal />;
};

PayPalRedirectionModalChapter.displayName = 'PayPal Redirection Modal';
