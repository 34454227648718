import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default () => {
  return (
    <Svg width="64" height="64" fill="none" viewBox="0 0 64 64">
      <Path
        fill="#E7E7E7"
        d="M64 32c0 17.673-14.327 32-32 32C14.327 64 0 49.673 0 32 0 14.327 14.327 0 32 0c17.673 0 
        32 14.327 32 32zM6.4 32c0 14.139 11.461 25.6 25.6 25.6 14.139 0 25.6-11.462 25.6-25.6C57.6 
        17.861 46.138 6.4 32 6.4 17.861 6.4 6.4 17.861 6.4 32z"
      ></Path>
      <Path fill="#003087" d="M32 0A32 32 0 110 32h6.4A25.6 25.6 0 1032 6.4V0z"></Path>
    </Svg>
  );
};
