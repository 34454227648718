import PKG from '../../package.json';

export type GlobalEventEmitterProps = {
  error?: unknown;
  message?: { id: string };
  package: string;
  version: string;
  component: string;
  context: { [key: string]: unknown };
  [key: string]: unknown;
};

export type GlobalEventEmitter = (
  eventId: { domain?: 'PAYMENTS'; event?: string },
  props: GlobalEventEmitterProps,
) => void;

export async function emitMonitoringError(
  publish: GlobalEventEmitter,
  component: string | { displayName: string },
  err: unknown,
  context?: { [key: string]: unknown },
) {
  publish(
    { domain: 'PAYMENTS', event: 'MONITORING.ERROR' },
    {
      error: err,
      package: PKG.name,
      version: PKG.version,
      component: getComponentName(component),
      context: { ...context },
    },
  );
}

export async function emitGlobalError(
  publish: GlobalEventEmitter,
  component: string | { displayName: string },
  err: unknown,
  context?: { [key: string]: unknown },
) {
  publish(
    { event: 'ERROR' },
    {
      error: err,
      package: PKG.name,
      version: PKG.version,
      component: getComponentName(component),
      context: { ...context },
    },
  );
}

export async function emitGlobalErrorAndMonitor(
  publish: GlobalEventEmitter,
  component: string | { displayName: string },
  err: unknown,
  context?: { [key: string]: unknown },
) {
  emitMonitoringError(publish, getComponentName(component), err, context);
  emitGlobalError(publish, getComponentName(component), err, context);
}

export async function emitPaymentInstrumentUpdated(
  publish: GlobalEventEmitter,
  component: string | { displayName: string },
  message: { id: string },
  context?: { [key: string]: unknown },
) {
  publish(
    { event: 'PAYMENT_INSTRUMENT_UPDATED' },
    {
      message,
      package: PKG.name,
      version: PKG.version,
      component: getComponentName(component),
      context: { ...context },
    },
  );
}

export async function emitPaymentInstrumentSelectToDelayedTokenization(
  publish: GlobalEventEmitter,
  component: string | { displayName: string },
  message: { id: string },
  context?: { [key: string]: unknown },
) {
  publish(
    { event: 'PAYMENT_INSTRUMENT_SELECTED_TO_DELAYED_TOKENIZATION' },
    {
      message,
      package: PKG.name,
      version: PKG.version,
      component: getComponentName(component),
      context: { ...context },
    },
  );
}

function getComponentName(component: string | { displayName: string }): string {
  return typeof component === 'string' ? component : component.displayName;
}

class EventManager {
  constructor(private publish: GlobalEventEmitter) {}

  public async emitMonitoringError(
    component: string | { displayName: string },
    err: unknown,
    context?: { [key: string]: unknown },
  ) {
    this.publish(
      { domain: 'PAYMENTS', event: 'MONITORING.ERROR' },
      {
        error: err,
        package: PKG.name,
        version: PKG.version,
        component: getComponentName(component),
        context: { ...context },
      },
    );
  }
}
