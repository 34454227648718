/* eslint-disable react/prop-types */
import { Modal, Portal, View } from '@lookiero/aurora';
import { useLocale } from '@lookiero/locale';
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';

import { useAvoidKeyboard } from '../../services/ioc';
import { WebView, WebViewParams } from '../WebView';
import { usePaymentFlow } from './internals/PaymentFlowHook';

type TranslateFunction = (a: { id: string }) => void;

export type PaymentFlowProps = {
  token: string;
  translate?: TranslateFunction;
};

export type PaymentFlowRef = ReturnType<typeof usePaymentFlow>['strategies'];

export const PaymentFlow = forwardRef<PaymentFlowRef | undefined, PaymentFlowProps>((p, ref) => {
  const { setAvoidKeyboard } = useAvoidKeyboard();
  const { state, strategies } = usePaymentFlow(p.token);
  useImperativeHandle(ref, () => strategies);
  const { translate: defaultTranslate } = useLocale();
  const translate = p.translate || defaultTranslate;

  useEffect(() => {
    setAvoidKeyboard(!state.challengeVisible);
    return () => {
      setAvoidKeyboard(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.challengeVisible]);

  return (
    <>
      <ChallengeModal
        url={state.challengeUrl}
        visible={state.challengeVisible}
        onLoad={state.onChallengeLoad.call}
        onClose={() => state.onChallengeClose.call('cancelled')}
        onFinish={() => state.onChallengeClose.call('finished')}
      />
      <PaymentInProgressModal translate={translate} enabled={state.loading || state.challengeLoading} />
    </>
  );
});
PaymentFlow.displayName = 'PaymentFlow';

const PaymentInProgressModal = (p: { enabled: boolean; translate: TranslateFunction }) => (
  <Portal id="payment-flow-payment-in-progress-modal">
    <Modal
      isVisible={p.enabled}
      title={p.translate({ id: 'payment.processing.title_bank_connecting' })}
      description={p.translate({ id: 'payment.processing.paragraph_card_check' })}
    ></Modal>
  </Portal>
);

const ChallengeModal = (p: {
  url?: string;
  visible: boolean;
  onLoad: () => void;
  onClose: () => void;
  onFinish: () => void;
}) => {
  return (
    <Portal id="payment-flow-challenge-modal">
      <WebViewModal
        url={p.url}
        visible={p.visible}
        onLoad={p.onLoad}
        onClose={p.onClose}
        onMessage={(m) => {
          if (!m.data) return;
          if (!m.data.payment_intent_authorization_finished) return;
          if (!m.data.payment_intent_authorization_finished.response) return;
          p.onFinish();
        }}
      />
    </Portal>
  );
};

const WebViewModal = (p: {
  url?: string;
  visible: boolean;
  onClose: () => void;
  onLoad: () => void;
  onMessage: WebViewParams['onMessage'];
}) => {
  return (
    <Portal id="payment-flow-webview-modal">
      <Modal
        fullHeight={true}
        scrollable={false}
        isVisible={p.visible}
        onClose={p.onClose}
        size={{ M: '2/3', L: '2/3' }}
        contentStyle={{ flex: 1, width: '100%', paddingHorizontal: 0, paddingBottom: 0 }}
      >
        <View style={{ flex: 1 }}>
          <WebView url={p.url} onLoad={p.onLoad} onMessage={p.onMessage} style={{ height: '100%' }} />
        </View>
      </Modal>
    </Portal>
  );
};
