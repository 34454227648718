import {
  ALIGN,
  COLOR,
  DISPLAY,
  IMAGE_RESIZE_MODES,
  SIZE,
  Image,
  Modal,
  Portal,
  Text,
  View,
  useDevice,
} from '@lookiero/aurora';
import { useLocale } from '@lookiero/locale';
import React from 'react';
import { Platform } from 'react-native';

import { ImageSizes } from '../../domain/models';
import { useAnimatedVisibility } from '../../hooks/useAnimatedVisibility';
import { getCDNImages } from '../../infrastructure/getCDNImages';
import { useRandomString } from '../../utils/randomString';
import { useController } from '../PaymentInstrumentSelect/PaymentInstrumentSelect.controller';
import { Spinner } from '../Spinner';
import { TEXT } from './PayPalRedirectionModal.definition';
import { style } from './PayPalRedirectionModal.styles';

export const PayPalRedirectionModal = () => {
  const { translate } = useLocale();
  const { screen } = useDevice();
  const modalHeight = screen.S ? screen.height - 48 : 'auto';
  const { src, srcSet } = getCDNImages('paypal-horizontal', ImageSizes.S);
  const panelVisibility = useController((s) => s.panelVisibility);
  const { isRendered, isVisible } = useAnimatedVisibility(panelVisibility.paypalRedirectionModal);
  const randomId = useRandomString(5);

  if (!isRendered) return <></>;
  return (
    <Portal id={'paypal-redirection-modal-' + randomId}>
      <Modal isVisible={isVisible}>
        <View
          alignItems={ALIGN.CENTER}
          justifyContent={ALIGN.CENTER}
          display={DISPLAY.FLEX}
          style={{ height: modalHeight }}
        >
          {Platform.OS !== 'android' && <Spinner isVisible={true} marginBottom={SIZE.L} />}
          <Text align={ALIGN.CENTER} color={COLOR.GRAYSCALE_XL} level={2} detail={true} marginBottom={SIZE.L}>
            {translate(TEXT.PARAGRAPH)}
          </Text>
          <View alignItems={ALIGN.CENTER}>
            <Image autoSize={true} resizeMode={IMAGE_RESIZE_MODES[0]} src={src} srcSet={srcSet} style={style.logo} />
          </View>
        </View>
      </Modal>
    </Portal>
  );
};
