import { getAppVersion, getPaymentsFrontVersion } from '../services/ioc';

export const getVersionHeaders = () => {
  return { 'App-Version': getAppVersion(), 'Payments-Front-Version': getPaymentsFrontVersion() };
};

export const getAuthHeaders = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export const getHeaders = (token: string) => ({ ...getAuthHeaders(token), ...getVersionHeaders() });
