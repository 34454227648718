export enum Engine {
  STRIPE = 'stripe',
  STRIPE_UK = 'stripe_uk',
}

export enum PaymentMethod {
  CARD = 'card',
  GOOGLE_PAY = 'google_pay',
  PAYPAL = 'paypal',
}

export enum PaymentInstrumentBrand {
  AMERICAN_EXPRESS = 'amex',
  CARD = 'card',
  MASTERCARD = 'mastercard',
  GOOGLE_PAY = 'google_pay',
  PAYPAL = 'paypal',
  VISA = 'visa',
}

export enum PaymentInstrumentNetwork {
  CARTES_BANCAIRES = 'cartes_bancaires',
}

export enum DelayedTokenizationStatus {
  CANCELED = 'canceled',
}

export type PaymentInstrumentVariant<TPaymentMethod extends PaymentMethod, TMetadata extends unknown> = {
  engine: Engine;
  payment_method: TPaymentMethod;
  metadata: TMetadata;
};

export type CardPaymentInstrument = PaymentInstrumentVariant<
  PaymentMethod.CARD,
  {
    last4: string;
    brand?: PaymentInstrumentBrand;
    expireMonth: number;
    expireYear: number;
    availableNetworks: PaymentInstrumentNetwork[];
  }
>;

export type GooglePayPaymentInstrument = PaymentInstrumentVariant<
  PaymentMethod.GOOGLE_PAY,
  {
    last4: string;
    brand?: PaymentInstrumentBrand;
    expireMonth: number;
    expireYear: number;
  }
>;

export type PayPalPaymentInstrument = PaymentInstrumentVariant<PaymentMethod.PAYPAL, { verifiedEmail: string }>;

export type PendingPaymentInstrument = {
  engine: Engine;
  payment_method: PaymentMethod;
};

export type PaymentInstrument = CardPaymentInstrument | GooglePayPaymentInstrument | PayPalPaymentInstrument;

export type AvailableIntegrations = {
  engine: Engine;
  availableMethods: PaymentMethod[];
}[];

export enum Section {
  BOX_OFON = 'box-ofon',
  BOX_CHECKOUT = 'box-checkout',
  BOX_PLACE_ORDER = 'box-place-order',
  DIRECT_BUY_CHECKOUT = 'direct-buy-checkout',
  MY_ACCOUNT = 'my-account',
}

export enum ImageSizes {
  S = 'S',
  M = 'M',
  L = 'L',
}

export enum CheckoutStatus {
  UNFULFILLED = 'UNFULFILLED',
  REJECTED = 'REJECTED',
  REQUIRES_ACTION = 'REQUIRES_ACTION',
  REQUIRED_ACTION_CANCELLED = 'REQUIRED_ACTION_CANCELLED',
  ERROR = 'ERROR',
  FULFILLED = 'FULFILLED',
  OVERPAID = 'OVERPAID',
}

export enum Countries {
  AT = 'AT',
  BE = 'BE',
  DE = 'DE',
  ES = 'ES',
  FR = 'FR',
  GB = 'GB',
  IT = 'IT',
  LU = 'LU',
  NL = 'NL',
  PT = 'PT',
}

export enum Locales {
  AT = 'de-AT',
  BE = 'fr-BE',
  DE = 'de-DE',
  ES = 'es-ES',
  FR = 'fr-FR',
  IT = 'it-IT',
  PT = 'pt-PT',
  UK = 'en-GB',
}

export enum Currencies {
  EUR = 'EUR',
  GBP = 'GBP',
}

export type Checkout = {
  orderId: string;
  items: Record<string, string>[];
};
