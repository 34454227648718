import { PortalProvider } from '@lookiero/aurora';
import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { Spinner } from '../components/Spinner';

export const SpinnerChapter = () => {
  const { boolean } = useStoryteller();

  return (
    <PortalProvider>
      <Spinner isVisible={boolean('isVisible', false)} />
    </PortalProvider>
  );
};

SpinnerChapter.displayName = 'Spinner';
