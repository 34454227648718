import { PaymentInstrumentBrand, PaymentInstrumentNetwork } from '../../domain/models';

export * from './PaymentInstrumentLogo';

type LogoMap = {
  logoName: string;
  paymentInstrumentBrand: PaymentInstrumentBrand;
  paymentInstrumentNetwork?: PaymentInstrumentNetwork;
};

/**
 * Logo Maps:
 * Register here every PaymentInstrumentBrand and PaymentInstrumentNetwork with their respective logo image.
 * If a network is specified, it has priority over the brand.
 */
const logoMaps: LogoMap[] = [
  mapLogo('amex', PaymentInstrumentBrand.AMERICAN_EXPRESS),
  mapLogo('card', PaymentInstrumentBrand.CARD),
  mapLogo('mastercard', PaymentInstrumentBrand.MASTERCARD),
  mapLogo('google_pay', PaymentInstrumentBrand.GOOGLE_PAY),
  mapLogo('paypal', PaymentInstrumentBrand.PAYPAL),
  mapLogo('visa', PaymentInstrumentBrand.VISA),
  mapLogo('cartes_bancaires', PaymentInstrumentBrand.CARD, PaymentInstrumentNetwork.CARTES_BANCAIRES),
];

function mapLogo(
  logoName: string,
  paymentInstrumentBrand: PaymentInstrumentBrand,
  paymentInstrumentNetwork?: PaymentInstrumentNetwork,
): LogoMap {
  return {
    logoName,
    paymentInstrumentBrand,
    paymentInstrumentNetwork,
  };
}

export const findLogo = (
  paymentInstrumentBrand: PaymentInstrumentBrand,
  paymentInstrumentNetworks?: PaymentInstrumentNetwork[],
) => {
  if (paymentInstrumentNetworks) {
    const logo = logoMaps.find((x) => paymentInstrumentNetworks.find((y) => x.paymentInstrumentNetwork === y));
    if (logo) {
      return logo.logoName;
    }
  }
  const logo = logoMaps.find((logo) => logo.paymentInstrumentBrand === paymentInstrumentBrand);
  if (logo) {
    return logo.logoName;
  }
  return findFallbackLogo(paymentInstrumentBrand);
};

export const findFallbackLogo = (paymentInstrumentBrand: PaymentInstrumentBrand) => {
  switch (paymentInstrumentBrand) {
    case PaymentInstrumentBrand.GOOGLE_PAY:
      return 'google_pay';
    case PaymentInstrumentBrand.PAYPAL:
      return 'paypal';
    default:
      return 'card';
  }
};
