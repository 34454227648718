import { Stripe, StripeCardNumberElement } from '@stripe/stripe-js';
import { CreatePaymentMethodResult, PaymentMethodCreateParams } from '@stripe/stripe-react-native';

import { Engine } from '../../../domain/models';
import { updateCardNative, updateCardWeb } from '../CardUpdater.controller';

export type CreatePaymentMethodType = (
  data: PaymentMethodCreateParams.Params,
  options?: PaymentMethodCreateParams.Options,
) => Promise<CreatePaymentMethodResult> | undefined;

type UpdateCardDataVariant<TPlatform extends 'web' | 'native', TExtra> = {
  token: string;
  platform: TPlatform;
} & TExtra;

type UpdateCardDataWeb = {
  cardNumberElement: StripeCardNumberElement;
  stripe: Stripe;
};

type UpdateCardDataNative = {
  createPaymentMethod: CreatePaymentMethodType;
};

type UpdateCardData =
  | (UpdateCardDataVariant<'web', UpdateCardDataWeb>)
  | (UpdateCardDataVariant<'native', UpdateCardDataNative>);

const updateCard = async (data: UpdateCardData, engine: Engine) => {
  if (data.platform === 'native') {
    return updateCardNative(data.createPaymentMethod, engine, data.token);
  }

  return updateCardWeb(data.cardNumberElement, engine, data.stripe, data.token);
};

export { updateCard, UpdateCardData };
