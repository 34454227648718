import { request } from '@lookiero/data-sources';

import { ChargeStatus } from './CheckoutAPI';
import { getHeaders } from './headers';

export interface ConfirmChargeResponse {
  chargeId: string;
  isCharged: boolean;
  status: ChargeStatus;
  metadata: Map<string, object>;
  declineCode: string;
  code: string;
}

export class LegacyBoxCheckoutRepository {
  constructor(private token: string) {}

  async confirmCharge(chargeId: string): Promise<ConfirmChargeResponse> {
    return await request({
      endpoint: '/web/api/payments/v1/charge/confirm',
      method: 'POST',
      headers: getHeaders(this.token),
      chargeId,
    });
  }
}
