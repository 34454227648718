import { SafeAreaView } from '@lookiero/aurora';
import { useEvent } from '@lookiero/event';
import { useStoryteller } from '@lookiero/storyteller';
import React, { useEffect } from 'react';

import { CardUpdater } from '../components/CardUpdater';
import { Engine } from '../domain/models';
import { PaymentsError } from '../domain/PaymentsError';
import { LocaleProvider } from './helpers';

export const CardUpdaterChapter = () => {
  const { event, select } = useStoryteller();
  const { subscribe, unsubscribe } = useEvent();

  useEffect(() => {
    const onErrorToast = (error: PaymentsError) => event('onError', error);
    const onMonitoringErrorToast = (error: PaymentsError) => event('onMonitoringError', error);
    const onSuccessToast = (payload: unknown) => event('onSuccess', payload);
    subscribe({ event: 'ERROR' }, onErrorToast);
    subscribe({ domain: 'PAYMENTS', event: 'MONITORING.ERROR' }, onMonitoringErrorToast);
    subscribe({ event: 'PAYMENT_INSTRUMENT_UPDATED' }, onSuccessToast);

    return () => {
      unsubscribe({ event: 'ERROR' }, onErrorToast);
      unsubscribe({ domain: 'PAYMENTS', event: 'MONITORING.ERROR' }, onMonitoringErrorToast);
      unsubscribe({ event: 'PAYMENT_INSTRUMENT_UPDATED' }, onSuccessToast);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LocaleProvider>
      <SafeAreaView style={{ flex: 1 }}>
        <CardUpdater
          engine={select('engine', Engine.STRIPE, {
            options: Object.values(Engine),
          })}
        />
      </SafeAreaView>
    </LocaleProvider>
  );
};

CardUpdaterChapter.displayName = 'Card Updater';
