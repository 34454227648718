import { useLocale } from '@lookiero/locale';
import React from 'react';

import { Engine } from '../../domain/models';
import { getMerchantInfo, getStripeKey } from '../../services/ioc';
import { StripeProvider } from '../StripeProvider';
import { getElementsOptions } from '../StripeProvider/StripeProvider.controller';
import { GooglePay } from './GooglePay';

export type GooglePayLoaderProps = {
  engine: Engine;
};

export const GooglePayLoader = (props: GooglePayLoaderProps) => {
  const { locale } = useLocale();

  if (!getMerchantInfo()) return <></>;
  return (
    <StripeProvider publishableKey={getStripeKey(props.engine)} options={getElementsOptions(locale)}>
      <GooglePay />
    </StripeProvider>
  );
};
