import { request } from '@lookiero/data-sources';

import { CheckoutStatus } from '../domain/models';
import { getHeaders } from './headers';

export enum ChargeStatus {
  EXECUTED='EXECUTED',
  REQUIRES_ACTION='REQUIRES_ACTION',
  REQUIRED_ACTION_CANCELLED='REQUIRED_ACTION_CANCELLED',
  REJECTED='REJECTED',
  CANCELLED='CANCELLED',
  TO_CONFIRM='TO_CONFIRM',
  ERROR='ERROR',
  UNKNOWN='UNKNOWN',
}

export type CheckoutOperationResult = {
  checkout: {
    status: CheckoutStatus;
    timeout: number;
  };
  charge?: {
    chargeId: string;
    status: ChargeStatus;
    declineCode?: string;
    metadata: {
      redirectToUrl_url?: string;
    };
  };
};

export type CheckoutAmount = {
    breakdown: {
      balance: number;
      paymentInstrument: number;
    };
    total: number;
}

export class CheckoutAPI {
  constructor(private token: string) {}

  public async reconcile(checkoutUuid: string): Promise<CheckoutOperationResult> {
    return await request({
      endpoint: '/web/api/checkout/v1/checkout/reconcile',
      method: 'POST',
      headers: getHeaders(this.token),

      uuid: checkoutUuid,
    });
  }

  public async confirmCharge(chargeId: string): Promise<CheckoutOperationResult> {
    return await request({
      endpoint: '/web/api/checkout/v1/confirm',
      method: 'POST',
      headers: getHeaders(this.token),

      chargeId,
    });
  }

  public async cancelCharge(chargeId: string, reason: string): Promise<void> {
    return await request({
      endpoint: '/web/api/checkout/v1/cancel',
      method: 'POST',
      headers: getHeaders(this.token),

      chargeId,
      reason,
    });
  }
}
