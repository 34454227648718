import { LocalAdapter, AsyncStorage } from '@lookiero/data-sources';

const storage = new AsyncStorage({ adapter: LocalAdapter });
const PAYMENTS_STORYTELLER_TOKEN = 'payments_storyteller_token';
const DEFAULT_TOKEN =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjQ4MTUxMTksImV4cCI6MTY2NjI1ODYyMSwiZGlzcGxheU5hbWUiOiJtYWlsLmRldit0ZHAuOThhZDNmZDgtZGJlOS00NWU5LWI0OWUtMmFmM2JmODU2ZTkxQGxvb2tpZXJvLmNvbSIsImNvdW50cnlfY29kZSI6IkdCIiwiYWNjZXNzVmlhIjoiZW1haWwiLCJzdWJzY3JpcHRpb25TdGFydGluZ0RhdGUiOiIyMDIyLTA5LTIwIiwiaW1wZXJzb25hdGVkIjpmYWxzZSwidXVpZCI6IjgyNTkwOWQ1LWJiOWItNDgyMi1hNDZmLTVjZTI5NmYxYjQ3OSIsImlhdCI6MTY2MzY2NjYyMX0.JFw53l3YqAEwq249Xy36A5BbK7kTMOZ_K5tOJ-sOij8';

export async function getToken() {
  return (await (await storage).get(PAYMENTS_STORYTELLER_TOKEN)) || DEFAULT_TOKEN;
}

export async function setToken(token) {
  await (await storage).set(PAYMENTS_STORYTELLER_TOKEN, token);
}

export async function resetToken() {
  await (await storage).remove(PAYMENTS_STORYTELLER_TOKEN);
}
