import { ALIGN, LAYOUT, Box, Layout, Text } from '@lookiero/aurora';
import { useLocale } from '@lookiero/locale';
import React, { useContext } from 'react';

import { AvailablePaymentMethods } from '../PaymentInstrumentSelect/internals';
import {
  PaymentInstrumentSelectControllerContext,
  useController,
} from '../PaymentInstrumentSelect/PaymentInstrumentSelect.controller';
import { TEXT } from '../PaymentInstrumentSelect/PaymentInstrumentSelect.definition';
import { PaymentPanel } from '../PaymentPanel';

export const PaymentInstrumentSelectPanel = () => {
  const storeInstance = useContext(PaymentInstrumentSelectControllerContext);
  const [setShowPaymentInstrumentSelectPanel, panelVisibility] = useController((s) => [
    s.setShowPaymentInstrumentSelectPanel,
    s.panelVisibility,
  ]);
  const { translate } = useLocale();

  const handleOnClose = () => setShowPaymentInstrumentSelectPanel(false);

  return (
    <PaymentPanel
      id="PaymentInstrumentSelectPanel"
      title={translate(TEXT.PANEL_PAYMENT_METHOD_SELECT_TITLE)}
      isVisible={panelVisibility.paymentInstrumentSelect}
      onClose={handleOnClose}
    >
      <PaymentInstrumentSelectControllerContext.Provider value={storeInstance}>
        <Layout alignItems={ALIGN.START} justifyContent={ALIGN.CENTER} testID="Layout">
          <Box
            size={{ M: '2/3', L: '1/3' }}
            style={{
              flexDirection: 'column',
            }}
          >
            <Text align={ALIGN.CENTER} level={1} marginBottom={LAYOUT.M}>
              {translate(TEXT.PANEL_PAYMENT_METHOD_SELECT_BODY)}
            </Text>
            <AvailablePaymentMethods />
          </Box>
        </Layout>
      </PaymentInstrumentSelectControllerContext.Provider>
    </PaymentPanel>
  );
};
