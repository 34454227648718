import { COLOR, DISPLAY, FLEX_DIRECTION, SIZE, Text, View } from '@lookiero/aurora';
import { useLocale } from '@lookiero/locale';
import React from 'react';

import { PaymentInstrumentBrand, CardPaymentInstrument, Engine } from '../../../../domain/models';
import { PaymentAccordion } from '../../../PaymentAccordion';
import { TEXT } from '../../PaymentInstrumentSelect.definition';

type CardAccordionProps = {
  paymentInstrument?: CardPaymentInstrument;
  engine: Engine.STRIPE | Engine.STRIPE_UK | null;
};

export const CardAccordion = (props: CardAccordionProps) => {
  const { translate } = useLocale();

  return (
    <PaymentAccordion
      paymentInstrumentBrand={props.paymentInstrument?.metadata.brand || PaymentInstrumentBrand.CARD}
      paymentInstrumentNetworks={props.paymentInstrument?.metadata.availableNetworks || []}
      testID={'CardAccordion'}
    >
      <View display={DISPLAY.FLEX} flexDirection={FLEX_DIRECTION.COLUMN} marginRight={SIZE.M} flex={SIZE.XS}>
        {props.paymentInstrument != null ? (
          <>
            <Text action upperCase>
              {translate(TEXT.PAYMENT_METHOD)}
            </Text>
            <Text color={COLOR.GRAYSCALE_XL} ellipsizeMode marginTop={SIZE.S} height={SIZE.XXS}>
              {`${translate(TEXT.CARD)} *${props.paymentInstrument.metadata.last4}`}
            </Text>
          </>
        ) : (
          <Text detail level={1}>
            {translate(TEXT.CREDIT_CARD)}
          </Text>
        )}
      </View>
    </PaymentAccordion>
  );
};
