import { version } from '../../package.json';
import { Engine } from '../domain/models';
import { TrackingEventBase, TrackingEvent } from '../tracking/events';
import assertUnreachable from '../utils/assertUnreachable';

const throwNotImplementedError = () => {
  throw new Error('Payments bridge has not been configured.');
};

export type PaymentsBridge = {
  getToken: () => Promise<string | undefined>;
  stripeKey: string;
  stripeUkKey: string;
  commonAssetsPath: string;
  paymentsAssetsPath: string;
  iOSInAppBrowserBannerHeight: number;
  useAvoidKeyboard: () => { setAvoidKeyboard: (enabled: boolean) => {} };
  emitTrackingEvent: (e: TrackingEventBase) => void;
  appVersion: string;
  graphqlUri: string;
  googlePay: {
    merchant: GooglePayMerchantInfo;
    isTestEnv: boolean;
  } | null;
  useFeatureFlags: () => Record<string, boolean>;
};

export type GooglePayMerchantInfo = {
  merchantId: string;
  merchantName: string;
};

const state: { bridge: PaymentsBridge } = {
  bridge: {
    getToken: () => throwNotImplementedError(),
    stripeKey: '',
    stripeUkKey: '',
    commonAssetsPath: '',
    paymentsAssetsPath: '',
    iOSInAppBrowserBannerHeight: 0,
    useAvoidKeyboard: () => throwNotImplementedError(),
    emitTrackingEvent: () => throwNotImplementedError(),
    appVersion: '',
    graphqlUri: '',
    googlePay: {
      merchant: { merchantId: '', merchantName: '' },
      isTestEnv: true,
    },
    useFeatureFlags: () => ({}),
  },
};

export const setPaymentsBridge = (newBridge: PaymentsBridge) => {
  state.bridge = newBridge;
};

export const getToken = () => state.bridge.getToken();
export const mustGetToken = async () => {
  const token = await getToken();
  if (!token) {
    throw new Error('An action required the user token and it is not available yet.');
  }
  return token;
};

export const getCommonAssetsPath = () => state.bridge.commonAssetsPath;
export const getPaymentsAssetsPath = () => state.bridge.paymentsAssetsPath;
export const getIOSInAppBrowserBannerHeight = () => state.bridge.iOSInAppBrowserBannerHeight;
export const useAvoidKeyboard = () => state.bridge.useAvoidKeyboard();
export const emitTrackingEvent = (e: TrackingEvent) => state.bridge.emitTrackingEvent(e);
export const getStripeKey = (engine: Engine): string => {
  if (engine === Engine.STRIPE) return state.bridge.stripeKey;
  if (engine === Engine.STRIPE_UK) return state.bridge.stripeUkKey;

  assertUnreachable(engine);
  return '';
};
export const getAppVersion = () => state.bridge.appVersion;
export const getGraphqlUri = () => state.bridge.graphqlUri;
export const getPaymentsFrontVersion = () => version;
export const getMerchantInfo = (): GooglePayMerchantInfo | null =>
  state.bridge.googlePay ? state.bridge.googlePay.merchant : null;
export const isTestEnv = () => (state.bridge.googlePay ? state.bridge.googlePay.isTestEnv : true);
export const useFeatureFlags = () => state.bridge.useFeatureFlags();
