import { Countries, Currencies, Locales } from '../domain/models';

export const getCountryByLocale = (locale: Locales) => {
  const country = locale.split('-')[1] as Countries;
  return Countries[country];
};

export const getCurrencyByLocale = (locale: Locales) => {
  if (locale === Locales.UK) {
    return Currencies.GBP;
  }
  return Currencies.EUR;
};
