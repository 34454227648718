import create from 'zustand';

import { Engine } from '../../domain/models';

export type StripeCardToken = `tok_${string}`;
export type StripePaymentMethodId = `pm_${string}`;
export enum GooglePayErrorCodes {
  FAILED = 'Failed',
  CANCELED = 'Canceled',
  UNKNOWN = 'Unknown',
}
export type GooglePayError = {
  code: GooglePayErrorCodes;
  message: string;
  localizedMessage?: string;
  declineCode?: string;
  stripeErrorCode?: string;
  type?: string;
};
export type GooglePaySheetResponseCard = {
  last4: string;
  brand: string;
  expireMonth: number;
  expireYear: number;
};

type GooglePaySheetResponseVariant<TId extends unknown, TCard extends unknown, TError extends unknown> = {
  id: TId;
  card: TCard;
  error: TError;
};

type GooglePaySheetSuccessResponse = GooglePaySheetResponseVariant<
  StripeCardToken | StripePaymentMethodId,
  GooglePaySheetResponseCard,
  undefined
>;

type GooglePaySheetErrorResponse = GooglePaySheetResponseVariant<undefined, undefined, GooglePayError>;

export type GooglePaySheetResponse = GooglePaySheetSuccessResponse | GooglePaySheetErrorResponse;

export type GooglePaySheetProps = {
  country: string;
  currency: string;
  engine: Engine;
  toAuthorizePayment: {
    description?: string;
    total: number;
    items?: {
      description: string;
      amount: number;
    }[];
  };
};

export type GooglePayState = {
  isAvailable: boolean;
  country: string;
  currency: string;
  loaded: boolean;
  ready: boolean;
  openGooglePaySheet: (opts: GooglePaySheetProps) => Promise<GooglePaySheetResponse>;
};

export const useGooglePayState = create<GooglePayState>((set, get) => ({
  isAvailable: false,
  country: '',
  currency: '',
  loaded: false,
  ready: false,
  openGooglePaySheet: () => Promise.reject(),
}));
