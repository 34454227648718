export const parseSearchParams = (url: string): { [key: string]: string[] } => {
  if (url.indexOf('?') === -1) return {};
  return url
    .split('?')[1]!
    .split('#')[0]!
    .split('&')
    .map((kv) => kv.split('=') as [string, string])
    .reduce(
      (result, [key, value]) => ({ ...result, [key]: [...(result[key] || []), value] }),
      {} as { [key: string]: string[] },
    );
};

export const getSearchParam = (url: string, key: string): string | null => {
  const values = parseSearchParams(url)[key] || [];
  return values[values.length - 1] || null;
};
