import { PaymentMethod } from '../../domain/models';
import { emitTrackingEvent, getToken } from '../../services/ioc';
import { GooglePayEventOutput } from '../../tracking/events';
import { parseUserToken } from '../../utils/parseUserToken';

export const emitGooglePayAvailability = async (output: GooglePayEventOutput) => {
  const token = await getToken();
  emitTrackingEvent({
    event: 'googlePayAvailability',
    paymentMethod: PaymentMethod.GOOGLE_PAY,
    output,
    uuid: token != null ? parseUserToken(token).uuid : null,
  });
};
