import { getMessageForDeclineCode } from '../../../../domain/declineCodes';
import { Checkout } from '../../../../domain/models';
import { ChargeStatus } from '../../../../infrastructure/CheckoutAPI';
import { LegacyBoxCheckoutAPI } from '../../../../infrastructure/LegacyBoxCheckoutAPI';
import assertUnreachable from '../../../../utils/assertUnreachable';
import { PaymentFlowStrategy } from '../PaymentFlowStrategy';

const TIMEOUT_MARGIN_IN_SECONDS = 15;
const DEFAULT_TIMEOUT_IN_SECONDS = 300;

enum ChallengeResult {
  CANCELED = 'cancelled',
  TIMED_OUT = 'timed_out',
  FINISHED = 'finished',
}

export type LegacyBoxCheckout = {
  status: ChargeStatus;
  toaster: { id: string } | null;
  final: boolean;
};

export class LegacyBoxCheckoutStrategy extends PaymentFlowStrategy<
  Checkout,
  LegacyBoxCheckout,
  {
    legacyBoxCheckoutAPI: LegacyBoxCheckoutAPI;
  }
> {
  public async start(checkout: Checkout) {
    try {
      const performCheckoutResult = await this.performCheckout(checkout);

      if (performCheckoutResult.status !== ChargeStatus.REQUIRES_ACTION) {
        return this.emit(performCheckoutResult.status, {
          final: true,
          toaster: getMessageForDeclineCode(performCheckoutResult?.declineCode),
        });
      }

      this.emit(ChargeStatus.REQUIRES_ACTION);
      const challengeResult = await this.ui.displayChallenge(
        performCheckoutResult.challengeUrl!,
        DEFAULT_TIMEOUT_IN_SECONDS - TIMEOUT_MARGIN_IN_SECONDS,
      );

      if (challengeResult === ChallengeResult.CANCELED) {
        return this.emit(ChargeStatus.REQUIRED_ACTION_CANCELLED, { final: true });
      }

      if (challengeResult === ChallengeResult.TIMED_OUT) {
        return this.emit(ChargeStatus.REQUIRED_ACTION_CANCELLED, {
          final: true,
          toaster: { id: 'payment.error.timeout2fa' },
        });
      }

      if (challengeResult === ChallengeResult.FINISHED) {
        const confirmationResult = await this.confirmCharge(performCheckoutResult.chargeId!);
        return this.emit(confirmationResult.status, {
          final: true,
          toaster: getMessageForDeclineCode(confirmationResult?.declineCode),
        });
      }

      assertUnreachable(challengeResult);
    } catch (err) {
      this.resetState();
      this.notifyUnexpectedError(err as Error, { checkout });
      this.emit(ChargeStatus.ERROR, {
        final: true,
        toaster: getMessageForDeclineCode((err as any)?.payload?.declineCode),
      });
    }
  }

  private async performCheckout(checkout: Checkout): Promise<PerformCheckoutResult> {
    const result = await this.ui.loadingWhile(this.deps.legacyBoxCheckoutAPI.performCheckout(checkout));
    if (result.data.performCheckout.errors && result.data.performCheckout.errors.length) {
      return {
        status: ChargeStatus.ERROR,
        declineCode: result.data.performCheckout.errors[0].code,
      };
    }
    const chargeExecution = result.data.performCheckout.chargeExecution;
    const metadata = JSON.parse(chargeExecution.metadata);

    return {
      status: chargeExecution.status,
      chargeId: chargeExecution.chargeId,
      declineCode: chargeExecution.status === ChargeStatus.REJECTED ? chargeExecution.declineCode : undefined,
      challengeUrl:
        chargeExecution.status === ChargeStatus.REQUIRES_ACTION && metadata.redirectToUrl_url
          ? metadata.redirectToUrl_url
          : undefined,
    };
  }

  private async confirmCharge(chargeId: string): Promise<ConfirmChargeResult> {
    const result = await this.ui.challengeLoadingWhile(this.deps.legacyBoxCheckoutAPI.confirmCharge(chargeId));
    return {
      status: result.status,
      declineCode: result.status === ChargeStatus.REJECTED ? result.declineCode : undefined,
    };
  }

  private emit(
    status: LegacyBoxCheckout['status'],
    extra?: {
      final?: LegacyBoxCheckout['final'];
      toaster?: LegacyBoxCheckout['toaster'];
    },
  ) {
    this.emitter({
      status,
      final: extra?.final || false,
      toaster: extra?.toaster || null,
    });
  }
}

type PerformCheckoutResult = {
  status: ChargeStatus;
  declineCode?: string;
  chargeId?: string;
  challengeUrl?: string;
};

type ConfirmChargeResult = {
  status: ChargeStatus;
  declineCode?: string;
};
