import { ALIGN, COLOR, LAYOUT, POSITION, SIZE, Button, Skeleton, View, useDevice } from '@lookiero/aurora';
import { useEvent } from '@lookiero/event';
import { useLocale } from '@lookiero/locale';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';

import { getMessageForDeclineCode } from '../../domain/declineCodes';
import { Engine, PaymentMethod } from '../../domain/models';
import { usePaymentInstrument } from '../../hooks/usePaymentInstrument';
import { emitTrackingEvent, mustGetToken } from '../../services/ioc';
import { TrackingCardFieldPart } from '../../tracking/events';
import { emitGlobalError, emitPaymentInstrumentUpdated, emitMonitoringError } from '../../utils/eventEmitter';
import { Label } from '../Label/Label';
import { useController } from '../PaymentInstrumentSelect/PaymentInstrumentSelect.controller';
import { updateCard } from './bridges';
import { CardElementType, TEXT } from './CardUpdater.definition';
import {
  getCardElementStyles,
  style,
  getLabelProps,
  getElementBorderColor,
  getInputHeight,
} from './CardUpdater.styles.web';

const fieldNameMap: { [key in CardElementType]: TrackingCardFieldPart } = {
  [CardElementType.CARD_NUMBER_ELEMENT]: 'cardNumber',
  [CardElementType.CARD_CVC_ELEMENT]: 'cvc',
  [CardElementType.CARD_EXPIRY_ELEMENT]: 'expirationDate',
};

export const CardUpdaterForm = ({ engine }: { engine: Engine }) => {
  const [hideAllPanels, removePendingPaymentInstrument] = useController((s) => [
    s.hideAllPanels,
    s.removePendingPaymentInstrument,
  ]);
  const { publish } = useEvent();
  const { platform, screen } = useDevice();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const elements = useElements()!;
  const { translate } = useLocale();
  const [complete, setComplete] = useState({} as Record<CardElementType, unknown>);
  const [error, setError] = useState({} as Record<string, unknown>);
  const [focus, setFocus] = useState<CardElementType>();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState({});
  const [ready, setReady] = useState({} as Record<CardElementType, unknown>);
  const [touched, setTouched] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const stripe = useStripe()!;
  const paymentInstrument = usePaymentInstrument();

  useEffect(() => {
    setOptions(getCardElementStyles());
  }, []);

  const handleBlur = (element: CardElementType) => {
    setTouched((state) => ({ ...state, [element]: true }));
    setFocus(undefined);
  };

  const handleChange = (
    event: StripeCardExpiryElementChangeEvent | StripeCardNumberElementChangeEvent | StripeCardCvcElementChangeEvent,
    element: CardElementType,
  ) => {
    const cardInfoAdded = (output: 'rejected' | 'success') =>
      emitTrackingEvent({
        event: 'cardInfoAdded',
        output,
        part: fieldNameMap[element],
      });

    if (event.complete) {
      cardInfoAdded('success');
    } else if (event.error) {
      cardInfoAdded('rejected');
    }

    setError((state) => ({ ...state, [element]: event.error }));
    setComplete((state) => ({ ...state, [element]: event.complete }));
  };

  const handleFocus = (focusedElement: CardElementType) => {
    setFocus(focusedElement);
    emitTrackingEvent({
      event: 'addCardInfo',
      part: fieldNameMap[focusedElement],
    });
  };

  const handleReady = (element: CardElementType) => {
    setReady((state) => ({ ...state, [element]: true }));
  };

  const emitError = (error: unknown) => {
    const errorIsUnexpected =
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (error as any)?.code !== 402 && (error as Error)?.name !== 'update_payment_instrument_error';

    emitTrackingEvent({
      event: 'paymentRegistration',
      paymentMethod: PaymentMethod.CARD,
      output: errorIsUnexpected ? 'error' : 'rejected',
    });

    if (errorIsUnexpected) {
      emitMonitoringError(publish, CardUpdaterForm.name, error);
    }
    emitGlobalError(publish, CardUpdaterForm.name, {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      toaster: getMessageForDeclineCode((error as any)?.payload?.code),
    });
  };

  const handleUpdatePress = async () => {
    try {
      setLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const cardNumberElement = elements.getElement(CardNumberElement)!;
      await updateCard({ platform: 'web', token: await mustGetToken(), cardNumberElement, stripe }, engine);
      await paymentInstrument.refresh();
      emitPaymentInstrumentUpdated(publish, CardUpdaterForm.name, TEXT.NOTIFICATION_SUCCESS);
      removePendingPaymentInstrument();
      setLoading(false);
      setTouched({});
      hideAllPanels();
      emitTrackingEvent({
        event: 'paymentRegistration',
        paymentMethod: PaymentMethod.CARD,
        output: 'success',
      });
    } catch (error) {
      emitError(error);
      setLoading(false);
    }
  };

  const areElementsReady = () => Object.values(CardElementType).every((element) => ready[element]);
  const isFormComplete = () => Object.values(CardElementType).every((element) => complete[element]);
  const isDesktop = platform.web && (screen.M || screen.L);
  const focusedElement = (element: CardElementType) => focus === element;
  const touchedElement = (element: CardElementType) => Object.keys(touched).some((key) => key === element);
  const shouldShowError = (element: CardElementType) =>
    !!(error && error[element]) || (!complete[element] && touchedElement(element) && !focusedElement(element));

  return (
    <View justifyContent={isDesktop ? ALIGN.START : ALIGN.BETWEEN}>
      <SkeletonWrapper visible={!areElementsReady()} />
      <View position={POSITION.RELATIVE} display={areElementsReady() ? undefined : 'none'}>
        <Label
          {...getLabelProps(
            focusedElement(CardElementType.CARD_NUMBER_ELEMENT),
            touchedElement(CardElementType.CARD_NUMBER_ELEMENT),
            shouldShowError(CardElementType.CARD_NUMBER_ELEMENT),
          )}
        >
          {translate(TEXT.INPUT_NUMBER)}
        </Label>
        <View
          backgroundColor={COLOR.BASE}
          borderColor={getElementBorderColor(
            focusedElement(CardElementType.CARD_NUMBER_ELEMENT),
            shouldShowError(CardElementType.CARD_NUMBER_ELEMENT),
          )}
          style={style.border}
          marginBottom={LAYOUT.XS}
          paddingHorizontal={SIZE.M}
          position={POSITION.RELATIVE}
        >
          <CardNumberElement
            onChange={(event) => handleChange(event, CardElementType.CARD_NUMBER_ELEMENT)}
            onBlur={() => handleBlur(CardElementType.CARD_NUMBER_ELEMENT)}
            onFocus={() => handleFocus(CardElementType.CARD_NUMBER_ELEMENT)}
            onReady={() => handleReady(CardElementType.CARD_NUMBER_ELEMENT)}
            options={options}
          />
        </View>
      </View>

      <SkeletonWrapper visible={!areElementsReady()} />
      <View position={POSITION.RELATIVE} display={areElementsReady() ? undefined : 'none'}>
        <Label
          {...getLabelProps(
            focusedElement(CardElementType.CARD_EXPIRY_ELEMENT),
            touchedElement(CardElementType.CARD_EXPIRY_ELEMENT),
            shouldShowError(CardElementType.CARD_EXPIRY_ELEMENT),
          )}
        >
          {translate(TEXT.INPUT_EXPIRATION_DATE)}
        </Label>
        <View
          backgroundColor={COLOR.BASE}
          borderColor={getElementBorderColor(
            focusedElement(CardElementType.CARD_EXPIRY_ELEMENT),
            shouldShowError(CardElementType.CARD_EXPIRY_ELEMENT),
          )}
          style={style.border}
          paddingHorizontal={SIZE.M}
          position={POSITION.RELATIVE}
          marginBottom={LAYOUT.XS}
        >
          <CardExpiryElement
            onBlur={() => handleBlur(CardElementType.CARD_EXPIRY_ELEMENT)}
            onChange={(event) => handleChange(event, CardElementType.CARD_EXPIRY_ELEMENT)}
            onFocus={() => handleFocus(CardElementType.CARD_EXPIRY_ELEMENT)}
            onReady={() => handleReady(CardElementType.CARD_EXPIRY_ELEMENT)}
            options={options}
          />
        </View>
      </View>

      <SkeletonWrapper visible={!areElementsReady()} />
      <View position={POSITION.RELATIVE} display={areElementsReady() ? undefined : 'none'}>
        <Label
          {...getLabelProps(
            focusedElement(CardElementType.CARD_CVC_ELEMENT),
            touchedElement(CardElementType.CARD_CVC_ELEMENT),
            shouldShowError(CardElementType.CARD_CVC_ELEMENT),
          )}
        >
          {translate(TEXT.INPUT_CVC)}
        </Label>
        <View
          backgroundColor={COLOR.BASE}
          borderColor={getElementBorderColor(
            focusedElement(CardElementType.CARD_CVC_ELEMENT),
            shouldShowError(CardElementType.CARD_CVC_ELEMENT),
          )}
          style={style.border}
          paddingHorizontal={SIZE.M}
          position={POSITION.RELATIVE}
          marginBottom={LAYOUT.XS}
        >
          <CardCvcElement
            onBlur={() => handleBlur(CardElementType.CARD_CVC_ELEMENT)}
            onChange={(event) => handleChange(event, CardElementType.CARD_CVC_ELEMENT)}
            onFocus={() => handleFocus(CardElementType.CARD_CVC_ELEMENT)}
            onReady={() => handleReady(CardElementType.CARD_CVC_ELEMENT)}
            options={options}
          />
        </View>
      </View>

      <Button
        busy={loading}
        disabled={!isFormComplete()}
        justifyContent={ALIGN.CENTER}
        marginTop={LAYOUT.XS}
        marginBottom={SIZE.M}
        testID={'updateCardButton'}
        type="button"
        onPress={handleUpdatePress}
      >
        {translate(TEXT.CTA_UPDATE)}
      </Button>
    </View>
  );
};

const SkeletonWrapper = ({ visible }: { visible: boolean }) =>
  visible ? (
    <View marginBottom={LAYOUT.XS}>
      <Skeleton height={getInputHeight()} />
    </View>
  ) : (
    <></>
  );
