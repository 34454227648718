import { View, Skeleton as SkeletonAurora, ALIGN, COLOR, DISPLAY, FLEX_DIRECTION, SIZE } from '@lookiero/aurora';
import React from 'react';

import { style } from './Skeleton.styles';

export const Skeleton = () => {
  return (
    <View
      alignItems={ALIGN.STRETCH}
      display={DISPLAY.FLEX}
      flexDirection={FLEX_DIRECTION.COLUMN}
      paddingVertical={SIZE.M}
      testID={'skeleton'}
      wide
    >
      <View
        style={style.header}
        borderColor={COLOR.GRAYSCALE_S}
        flexDirection={FLEX_DIRECTION.ROW}
        justifyContent={ALIGN.BETWEEN}
        paddingBottom={SIZE.M}
      >
        <View flexDirection={FLEX_DIRECTION.ROW}>
          <SkeletonAurora width={48} height={32} style={style.paymentInstrumentLogo} />
          <View
            alignCONTENT={ALIGN.START}
            alignItems={ALIGN.START}
            display={DISPLAY.FLEX}
            flexDirection={FLEX_DIRECTION.COLUMN}
            justifyContent={ALIGN.AROUND}
          >
            <SkeletonAurora width={64} height={16} marginBottom={SIZE.M} />
            <SkeletonAurora width={207} height={16} />
          </View>
        </View>
        <SkeletonAurora width={24} height={24} style={style.accordionIcon} />
      </View>
    </View>
  );
};
