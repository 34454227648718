import {
  PaymentMethod as PaymentMethodWeb,
  Stripe,
  StripeCardNumberElement,
} from '@stripe/stripe-js';
import { PaymentMethod as PaymentMethodNative } from '@stripe/stripe-react-native/lib/typescript/src/types';

import { Engine, PaymentMethod } from '../../domain/models';
import { paymentInstrumentRepository } from '../../infrastructure';
import { CreatePaymentMethodType } from './bridges/UpdateCard';

const changePaymentInstrument = async (
  engine: Engine,
  paymentMethod: PaymentMethodWeb | PaymentMethodNative,
  token: string,
) => {
  const { errors: [error] = [] } = await paymentInstrumentRepository.changePaymentInstrumentByPaymentMethodId(
    engine,
    PaymentMethod.CARD,
    paymentMethod.id,
    token,
  );
  if (error) {
    throw error;
  }
  return paymentMethod;
};

const createPaymentMethod = async ({ element, stripe }: { element: StripeCardNumberElement; stripe: Stripe }) =>
  (
    await stripe.createPaymentMethod({
      type: 'card',
      card: element,
    })
  ).paymentMethod;

const paymentInstrumentError = (): Error => {
  return new Error('Payment Instrument Error', {
    cause: {
      name: 'update_payment_instrument_error',
      message: 'We had a problem creating the payment instrument, please try again later.',
    },
  });
};

const updateCardNative = async (createPaymentMethod: CreatePaymentMethodType, engine: Engine, token: string) => {
  const response = await createPaymentMethod({
    type: 'Card',
  });

  if (!response) {
    throw paymentInstrumentError();
  }

  if (response.error) {
    throw response.error;
  }

  return await changePaymentInstrument(engine, response.paymentMethod, token);
};

const updateCardWeb = async (element: StripeCardNumberElement, engine: Engine, stripe: Stripe, token: string) => {
  const paymentMethod = await createPaymentMethod({ element, stripe });
  if (!paymentMethod) {
    throw paymentInstrumentError();
  }
  return await changePaymentInstrument(engine, paymentMethod, token);
};

export { updateCardNative, updateCardWeb };
