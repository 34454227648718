import { setProxy } from '@lookiero/data-sources';
import { EventProvider } from '@lookiero/event';
import { Chapter, Storyteller } from '@lookiero/storyteller';
import React from 'react';

import lookierorc from './lookiero.config';
import { PaymentsFrontLoader, PaymentsQueryProvider, Section, setPaymentsBridge } from './src';
import * as chapters from './src/__stories__';
import { getToken } from './src/__stories__/helpers/tokenStore';
import { useControllerFactory } from './src/components/PaymentInstrumentSelect/PaymentInstrumentSelect.controller';
import { isWeb } from './src/utils/platform';

setPaymentsBridge({
  getToken,
  stripeKey: 'pk_test_6dwdUfkUm7AaUH3oSje9H0kk',
  stripeUkKey:
    'pk_test_51Jw6NEHQ1gSUXaQ8KKBLBhmzw7k0TCTN0GQ6qq8oGRmXCn09ptn7zrHX5jsyidv8iBDGuzUDV3R0fko01m5oJzMO00z6XmoLbn',
  commonAssetsPath: 'https://cdn.dev.envs.lookiero.tech/commons',
  paymentsAssetsPath: 'https://cdn.dev.envs.lookiero.tech/payments-front',
  iOSInAppBrowserBannerHeight: 70,
  useAvoidKeyboard: () => ({
    // eslint-disable-next-line no-console
    setAvoidKeyboard: async (state) => console.log('setAvoidKeyboard: ', state),
  }),
  emitTrackingEvent: (e) => {
    // eslint-disable-next-line no-console
    console.log('Tracking event', e);
  },
  appVersion: '0.0.0-local',
  graphqlUri: '/bfu/graphql',
  googlePay: {
    merchant: {
      merchantId: '0123456789',
      merchantName: 'Lookiero',
    },
    isTestEnv: true,
  },
  useFeatureFlags: () => ({
    ALLOW_GOOGLE_PAY_AT: true,
    ALLOW_GOOGLE_PAY_BE: true,
    ALLOW_GOOGLE_PAY_DE: true,
    ALLOW_GOOGLE_PAY_ES: true,
    ALLOW_GOOGLE_PAY_FR: true,
    ALLOW_GOOGLE_PAY_GB: true,
    ALLOW_GOOGLE_PAY_IT: true,
    ALLOW_GOOGLE_PAY_LU: true,
    ALLOW_GOOGLE_PAY_NL: true,
    ALLOW_GOOGLE_PAY_PT: true,
    ALLOW_GOOGLE_PAY_APP_AT: true,
    ALLOW_GOOGLE_PAY_APP_BE: true,
    ALLOW_GOOGLE_PAY_APP_DE: true,
    ALLOW_GOOGLE_PAY_APP_ES: true,
    ALLOW_GOOGLE_PAY_APP_FR: true,
    ALLOW_GOOGLE_PAY_APP_GB: true,
    ALLOW_GOOGLE_PAY_APP_IT: true,
    ALLOW_GOOGLE_PAY_APP_LU: true,
    ALLOW_GOOGLE_PAY_APP_NL: true,
    ALLOW_GOOGLE_PAY_APP_PT: true,
  }),
});

if (!isWeb) {
  const { development: { devServer: { proxy } = {} } = {} } = lookierorc;

  setProxy(proxy);
}

export const App = () => {
  const ControllerProvider = useControllerFactory({
    allowDelayedTokenization: false,
    availableIntegrations: [],
    hasError: false,
    hidePaymentMethods: [],
    section: Section.BOX_CHECKOUT,
    beforeRedirectCallback: () => Promise.resolve(''),
  });

  return (
    <PaymentsQueryProvider>
      <EventProvider>
        <ControllerProvider>
          <PaymentsFrontLoader />
          <>
            <Storyteller title={lookierorc.name} version={lookierorc.version}>
              {Object.values(chapters).map((chapter, index) =>
                React.createElement(
                  Chapter,
                  { key: `chapter:${index}`, title: chapter.displayName },
                  React.createElement(chapter),
                ),
              )}
            </Storyteller>
          </>
        </ControllerProvider>
      </EventProvider>
    </PaymentsQueryProvider>
  );
};

export default App;
