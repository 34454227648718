import { POSITION, SIZE, Motion, Theme, Text, useBanProps, useSupportedProps } from '@lookiero/aurora';
import PropTypes from 'prop-types';
import React from 'react';

const CONTAINER_PROPS = ['style', 'layer', 'position'];

const theme = Theme.get();

type LabelProps = {
  children: React.ReactNode;
  level: number;
};

const Label = ({ children, ...props }: LabelProps) => (
  <>
    <Motion
      {...useSupportedProps(props, CONTAINER_PROPS)}
      pointerEvents="none"
      position={POSITION.ABSOLUTE}
      layer={SIZE.L}
      value={{
        top: props.level === 1 ? theme.spaceM : (Number(theme.spaceS) + Number(theme.spaceXXS) + 1) * -1,
        left: props.level === 1 ? theme.spaceM : Number(theme.spaceM) - Number(theme.spaceXS),
      }}
    >
      <Text {...useBanProps(props, CONTAINER_PROPS)}>{children}</Text>
    </Motion>
  </>
);

Label.propTypes = {
  children: PropTypes.string.isRequired,
  level: PropTypes.number,
};

export { Label };
