import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { PaymentPanel } from '../components/PaymentPanel';
import { Children } from './helpers';

export const PaymentPanelChapter = () => {
  const { event, boolean, text } = useStoryteller();

  return (
    <PaymentPanel
      title={text('title', 'Panel Title')}
      isVisible={boolean('isVisible', false)}
      onClose={() => event('onClose')}
      id={'PaymentPanelChapter'}
    >
      <Children text={'Payment Panel Body'} />
    </PaymentPanel>
  );
};

PaymentPanelChapter.displayName = 'Payment Panel';
