import { COLOR, DISPLAY, FLEX_DIRECTION, SIZE, Text, View } from '@lookiero/aurora';
import { useLocale } from '@lookiero/locale';
import React from 'react';

import { PaymentInstrumentBrand, Engine, GooglePayPaymentInstrument } from '../../../../domain/models';
import { PaymentAccordion } from '../../../PaymentAccordion';
import { useController } from '../../PaymentInstrumentSelect.controller';
import { TEXT } from '../../PaymentInstrumentSelect.definition';

type GooglePayAccordionProps = {
  paymentInstrument?: GooglePayPaymentInstrument;
  engine: Engine.STRIPE | Engine.STRIPE_UK | null;
};

export const GooglePayAccordion = (props: GooglePayAccordionProps) => {
  const { translate } = useLocale();
  const controller = useController((s) => s);
  const brand = props.paymentInstrument?.metadata?.brand
    ? `${props.paymentInstrument?.metadata.brand?.replace(/^\w/, (c) => c.toUpperCase())}`
    : translate(TEXT.CARD);

  return (
    <PaymentAccordion paymentInstrumentBrand={PaymentInstrumentBrand.GOOGLE_PAY} testID={'GooglePayAccordion'}>
      <View display={DISPLAY.FLEX} flexDirection={FLEX_DIRECTION.COLUMN} marginRight={SIZE.M} flex={SIZE.XS}>
        {props.paymentInstrument != null ? (
          <>
            <Text action upperCase>
              {translate(TEXT.GOOGLE_PAY)}
            </Text>
            <Text color={COLOR.GRAYSCALE_XL} ellipsizeMode marginTop={SIZE.S} height={SIZE.XXS}>
              {controller.getPendingPaymentInstrument()
                ? translate(TEXT.GOOGLE_PAY_PLACEHOLDER)
                : `${brand} *${props.paymentInstrument.metadata.last4}`}
            </Text>
          </>
        ) : (
          <Text detail level={1}>
            {translate(TEXT.GOOGLE_PAY)}
          </Text>
        )}
      </View>
    </PaymentAccordion>
  );
};
