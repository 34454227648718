/**
 * Given a React useState setter, builds a generic function that allows to set
 * specific parameters maintaining the rest untouched.
 * 
 * const [state, setState] = useState({ name: "Peter", surname: "Molyneux" })
 * const set = mutator(setState)
 * set("name", "John")
 * 
 * After that, state is {"name": "John", "surname": "Molyneux"}
 */

export type Mutator<T> = <K extends keyof T>(key: K, value: T[K]) => T[K]
export type ReactStateSetter<T> = React.Dispatch<React.SetStateAction<T>>

export const mutator = <T>(setter: React.Dispatch<React.SetStateAction<T>>): Mutator<T> =>
  <K extends keyof T>(key: K, value: T[K]) => {
    setter((s) => ({ ...s, [key]: value }));
    return value;
  }
