import { emitGlobalErrorAndMonitor, GlobalEventEmitter } from "../../../utils/eventEmitter";
import { PaymentFlowState } from "./PaymentFlowHook";
import { PaymentFlowUI } from "./PaymentFlowUI";
import { mutator, Mutator, ReactStateSetter } from "./utils/mutator";


export type PaymentFlowStrategyBaseDependencies<TEvent, TDependencies extends PaymentFlowStrategyDependencies> = {
  set: Mutator<PaymentFlowState>,
  resetState: () => void,
  emitter: (e: TEvent) => void,
  globalEventEmitter: GlobalEventEmitter,
  deps: TDependencies
}

export type PaymentFlowStrategyDependencies = {
  [key: string]: unknown
};

export abstract class PaymentFlowStrategy<TValue, TEvent, TDependencies extends PaymentFlowStrategyDependencies> {

  private globalEventEmitter: GlobalEventEmitter
  public resetState: () => void
  public emitter: (e: TEvent) => void
  public deps: TDependencies
  public ui: PaymentFlowUI

  constructor(baseDeps: PaymentFlowStrategyBaseDependencies<TEvent, TDependencies>) {
    this.globalEventEmitter = baseDeps.globalEventEmitter
    this.resetState = baseDeps.resetState
    this.emitter = baseDeps.emitter
    this.deps = baseDeps.deps
    this.ui = new PaymentFlowUI(baseDeps.set)
  }

  abstract start(value: TValue): Promise<void>;

  public async notifyUnexpectedError(err: Error, context: { [key: string]: unknown }) {
    emitGlobalErrorAndMonitor(this.globalEventEmitter, 'PaymentFlow', err, {
      process: 'payment_flow',
      ...context
    });
  }

}

export function strategyBuilder(
    strategyDeps: {
      initialState: PaymentFlowState,
      setState: ReactStateSetter<PaymentFlowState>,
      emitGlobalError: GlobalEventEmitter,
    },
  ) {

    return <
      TValue,
      TEvent,
      TDependencies extends PaymentFlowStrategyDependencies
    >(
      clazz: new (baseDeps: PaymentFlowStrategyBaseDependencies<TEvent, TDependencies>) =>
        PaymentFlowStrategy<TValue, TEvent, TDependencies>,
      deps: TDependencies
    ) => {

      return (value: TValue, emit: (e: TEvent) => void) =>
        new clazz({
          set: mutator(strategyDeps.setState),
          resetState: () => strategyDeps.setState(strategyDeps.initialState),
          emitter: emit,
          globalEventEmitter: strategyDeps.emitGlobalError,
          deps
        }).start(value)

    }

}
