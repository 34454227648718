import { Checkout } from '../domain/models';
import { LegacyBoxCheckoutRepository as LegacyBoxCheckoutGraphqlRepository } from './graphql/LegacyBoxCheckoutRepository';
import { LegacyBoxCheckoutRepository } from './LegacyBoxCheckoutRepository';

export class LegacyBoxCheckoutAPI {
  constructor(
    private legacyBoxCheckoutGraphqlRepository: LegacyBoxCheckoutGraphqlRepository,
    private legacyBoxCheckoutRepository: LegacyBoxCheckoutRepository,
  ) {}

  public async performCheckout(checkout: Checkout) {
    return this.legacyBoxCheckoutGraphqlRepository.performCheckout(checkout)
  }

  public async confirmCharge(chargeId: string) {
    return this.legacyBoxCheckoutRepository.confirmCharge(chargeId);
  }
}
