import { PaymentMethod, Engine, PaymentInstrumentVariant, PendingPaymentInstrument } from '../../../domain/models';
import { PaymentInstrumentSelectController } from '../PaymentInstrumentSelect.controller';
import { CardAccordion } from './CardAccordion';
import { GooglePayAccordion } from './GooglePayAccordion';
import { AccordionHandlerType, cardAccordionHandler, googlePayAccordionHandler, paypalAccordionHandler } from './handlers';
import { PaypalAccordion } from './PaypalAccordion';

/**
 * Accordion Maps:
 * Register here every available combination of PaymentMethod/Engine with it's
 * associated accordion and handler implementation.
 */
export const accordionMaps: AccordionMap[] = [
  map(PaymentMethod.CARD, Engine.STRIPE, CardAccordion, cardAccordionHandler, false),
  map(PaymentMethod.CARD, Engine.STRIPE_UK, CardAccordion, cardAccordionHandler, false),
  map(PaymentMethod.GOOGLE_PAY, Engine.STRIPE, GooglePayAccordion, googlePayAccordionHandler, true),
  map(PaymentMethod.GOOGLE_PAY, Engine.STRIPE_UK, GooglePayAccordion, googlePayAccordionHandler, true),
  map(PaymentMethod.PAYPAL, Engine.STRIPE, PaypalAccordion, paypalAccordionHandler, false),
  map(PaymentMethod.PAYPAL, Engine.STRIPE_UK, PaypalAccordion, paypalAccordionHandler, false),
];
/**/

export type AccordionMap = {
  paymentMethod: PaymentMethod;
  engine: Engine;
  component: AccordionComponent<PaymentInstrumentVariant<PaymentMethod, unknown> | PendingPaymentInstrument |undefined, Engine | null>;
  handler: (args: AccordionHandlerType) => void | Promise<void>;
  delayable: boolean;
};

export type AccordionComponent<
  PI extends PaymentInstrumentVariant<PaymentMethod, unknown> | PendingPaymentInstrument | undefined,
  E extends Engine | null,
> = (args: { paymentInstrument?: PI; engine: E }) => JSX.Element;

function map<PI extends PaymentInstrumentVariant<PaymentMethod, unknown>, E extends Engine>(
  paymentMethod: PI['payment_method'],
  engine: E,
  component: AccordionComponent<PI, E | null>,
  handler: (args: { controller: PaymentInstrumentSelectController; engine: E }) => void,
  delayable: boolean
): AccordionMap {
  return {
    paymentMethod,
    engine,
    component: component as AccordionMap['component'],
    handler: handler as AccordionMap['handler'],
    delayable,
  };
}

export const getAccordionForPaymentInstrument = <
  PI extends PaymentInstrumentVariant<PaymentMethod, unknown>,
  E extends Engine,
>(
  paymentInstrument: PI,
  engine: E,
): { Accordion: AccordionComponent<PI, E | null>; handler: AccordionMap['handler'] } => {
  const accordion = findAccordionMap(paymentInstrument.payment_method, engine);
  return { Accordion: accordion.component, handler: accordion.handler };
};

export const getAccordion = <E extends Engine>(
  paymentMethod: PaymentMethod,
  engine: E,
): { Accordion: AccordionComponent<undefined, E | null>; handler: AccordionMap['handler'], delayable: boolean } => {
  const accordion = findAccordionMap(paymentMethod, engine);
  return { Accordion: accordion.component, handler: accordion.handler, delayable: accordion.delayable };
};

export const getAccordionForPendingPaymentMethod = <E extends Engine>(
  paymentMethod: PaymentMethod,
  engine: E,
): { Accordion: AccordionComponent<PendingPaymentInstrument, E | null>; handler: AccordionMap['handler'], delayable: boolean } => {
  const accordion = findAccordionMap(paymentMethod, engine);
  return { Accordion: accordion.component, handler: accordion.handler, delayable: accordion.delayable };
};

export const findAccordionMap = (paymentMethod: PaymentMethod, engine: Engine) =>
  accordionMaps.filter((a) => a.paymentMethod === paymentMethod && a.engine === engine)[0]!;
