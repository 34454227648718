import StyleSheet from 'react-native-extended-stylesheet';

import { getIOSInAppBrowserBannerHeight } from '../../services/ioc';

export const style = StyleSheet.create({
  inAppBrowserBanner: {
    width: '100%',
    top: 0,
    left: 0,
    zIndex: '$elevationLayerM',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'black',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bannerText: { paddingTop: 0 },
});
