import { COLOR, POINTER, POSITION, SIZE, styles, Theme } from '@lookiero/aurora';
import StyleSheet from 'react-native-extended-stylesheet';

const theme = Theme.get();

const getInputHeight = () => theme.inputSize - theme.borderSize * 2;

const style = StyleSheet.create({
  border: {
    borderStyle: '$borderStyle',
    borderWidth: '$borderSize',
    height: getInputHeight,
    overflow: 'hidden',
  },
  label: {
    left: '$spaceM',
    top: '$spaceM',
    width: 250,
  },
  labelFocus: {
    left: '$spaceM - $spaceXS',
    top: () => (theme.spaceS + theme.spaceXXS + 1) * -1,
    borderLeftWidth: '$spaceXS',
    borderLeftColor: 'transparent',
    borderRightWidth: '$spaceXS',
    borderRightColor: 'transparent',
    height: '$spaceM',
    paddingLeft: '$spaceXS',
    paddingRight: '$spaceXS',
    backgroundColor: '$colorBase',
    width: 'auto',
  },
  logo: {
    flex: 0,
    aspectRatio: undefined,
    height: '$layoutS',
  },
});

const getCardElementStyles = () => {
  return {
    style: {
      base: {
        color: theme.colorContent,
        fontFamily: 'canela, courier',
        fontSize: '16px',
        lineHeight: '52px',
        ':focus::placeholder': { color: theme.colorGrayscaleL },
      },
      invalid: {
        color: theme.colorPrimary,
      },
    },
  };
};

const getElementBorderColor = (isFocusedElement: boolean, showError: boolean) => {
  if (showError) {
    return COLOR.PRIMARY;
  }
  if (isFocusedElement) {
    return COLOR.CONTENT;
  }

  return COLOR.GRAYSCALE_L;
};

const getLabelProps = (isFocusedElement: boolean, isTouchedElement: boolean, showError: boolean) => ({
  as: 'label',
  backgroundColor: COLOR.BASE,
  color: getElementBorderColor(isFocusedElement, showError),
  style: styles(style.label, (isTouchedElement || isFocusedElement) && style.labelFocus),
  detail: true,
  ellipsizeMode: true,
  layer: SIZE.XS,
  level: isTouchedElement || isFocusedElement ? 2 : 1,
  pointer: POINTER.NONE,
  position: POSITION.ABSOLUTE,
});

export {
  getCardElementStyles,
  getElementBorderColor,
  getInputHeight,
  getLabelProps,
  style,
  styles,
};
