import { View } from '@lookiero/aurora';
import { useEvent } from '@lookiero/event';
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';

import { PaymentMethod, Section } from '../../domain/models';
import { usePaymentInstrument } from '../../hooks/usePaymentInstrument';
import { usePayPalFinalizerState } from '../../services/PayPalFinalizer';
import { emitPaymentInstrumentUpdated, emitGlobalErrorAndMonitor } from '../../utils/eventEmitter';
import { CardUpdaterPanel } from '../CardUpdaterPanel';
import { LoadingModal } from '../LoadingModal';
import { PaymentInstrumentSelectPanel } from '../PaymentInstrumentSelectPanel';
import { PayPalRedirectionModal } from '../PayPalRedirectionModal';
import { AvailablePaymentMethods } from './internals';
import { useAvailableIntegrations } from './internals/AvailableIntegrations';
import { CurrentPaymentMethod } from './internals/CurrentPaymentMethod';
import { DelayedTokenizer, DelayedTokenizerRef } from './internals/DelayedTokenizer';
import { PendingPaymentMethod } from './internals/PendingPaymentMethod/PendingPaymentMethod';
import { Skeleton } from './internals/Skeleton';
import { useController, useControllerFactory } from './PaymentInstrumentSelect.controller';
import { TEXT } from './PaymentInstrumentSelect.definition';

export type PaymentInstrumentSelectProps = {
  allowDelayedTokenization?: boolean;
  hasError?: boolean;
  hidePaymentMethods?: PaymentMethod[];
  priceForTokenization?: number;
  section: Section;
  beforeRedirect?: () => Promise<string>;
};

export type PaymentInstrumentSelectRef = {
  runDelayedTokenization?: () => Promise<void>;
  hasPendingDelayedTokenization?: () => boolean;
};

export const PaymentInstrumentSelect = forwardRef((props: PaymentInstrumentSelectProps, ref) => {
  const availableIntegrations = useAvailableIntegrations(props.section);
  const delayedTokenizerRef = useRef<DelayedTokenizerRef>();

  const ControllerProvider = useControllerFactory({
    allowDelayedTokenization: !!props.allowDelayedTokenization,
    availableIntegrations,
    hasError: props.hasError || false,
    hidePaymentMethods: props.hidePaymentMethods || [],
    section: props.section,
    beforeRedirectCallback: props.beforeRedirect,
  });

  useImperativeHandle(
    ref,
    (): PaymentInstrumentSelectRef => ({
      runDelayedTokenization: delayedTokenizerRef?.current?.runDelayedTokenization,
      hasPendingDelayedTokenization: delayedTokenizerRef?.current?.hasPendingDelayedTokenization,
    }),
  );

  return (
    <>
      <ControllerProvider>
        <PaymentInstrumentSelectPanel />
        <CardUpdaterPanel />
        <PayPalRedirectionModal />
        <LoadingModal />
        <PaymentInstrumentSelectComposer />
        <DelayedTokenizer priceForTokenization={props.priceForTokenization || 0} ref={delayedTokenizerRef} />
        <PayPalFinalizer />
      </ControllerProvider>
    </>
  );
});

const PaymentInstrumentSelectComposer = () => {
  const paymentInstrument = usePaymentInstrument();
  const controller = useController((s) => s);
  const [paypalIsFinalizing] = usePayPalFinalizerState((s) => [s.finalizing]);
  const isLoading = controller.paymentInstrumentLoading || paymentInstrument.loading || paypalIsFinalizing;
  const pendingPaymentInstrument = controller.getPendingPaymentInstrument();
  return (
    <View>
      {(() => {
        if (isLoading) return <Skeleton />;
        if (pendingPaymentInstrument != null) {
          return <PendingPaymentMethod paymentInstrument={pendingPaymentInstrument} />;
        }
        if (paymentInstrument.info != null) {
          return <CurrentPaymentMethod paymentInstrument={paymentInstrument.info} />;
        }
        return <AvailablePaymentMethods />;
      })()}
    </View>
  );
};

const PayPalFinalizer = () => {
  const paymentInstrument = usePaymentInstrument();
  const payPalFinalizerState = usePayPalFinalizerState();
  const [hideAllPanels, removePendingPaymentInstrument] = useController((s) => [
    s.hideAllPanels,
    s.removePendingPaymentInstrument,
  ]);
  const { publish } = useEvent();

  useEffect(() => {
    payPalFinalizerState.finalizing && hideAllPanels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payPalFinalizerState.finalizing]);

  useEffect(() => {
    payPalFinalizerState.consumeResult(async (r) => {
      hideAllPanels();
      await paymentInstrument.refresh();
      if (r.success) {
        removePendingPaymentInstrument();
        setTimeout(() => emitPaymentInstrumentUpdated(publish, PayPalFinalizer.name, TEXT.NOTIFICATION_SUCCESS), 0);
      } else {
        emitGlobalErrorAndMonitor(publish, PayPalFinalizer.name, { toaster: TEXT.NOT_FINALIZED });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payPalFinalizerState.resultQueue.length]);

  return <></>;
};
