/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import { SafeAreaView, Button, Text, View, SIZE } from '@lookiero/aurora';
import { useEvent } from '@lookiero/event';
import { useStoryteller } from '@lookiero/storyteller';
import React, { useEffect, useRef } from 'react';

import { PaymentFlow, PaymentFlowRef } from '../components/PaymentFlow';
import { graphqlClient } from '../infrastructure/graphql';
import { GlobalEventEmitterProps } from '../utils/eventEmitter';
import { LocaleProvider } from './helpers';
import { USER_SUMMARY } from './helpers/graphql/queries/checkout';

const DEFAULT_TOKEN =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjI3NjAxMjIsImV4cCI6MTYzNDgyOTQzMywiZGlzcGxheU5hbWUiOiJKb3JkaSIsImNvdW50cnlfY29kZSI6IkVTIiwiYWNjZXNzVmlhIjoiZW1haWwiLCJzdWJzY3JpcHRpb25TdGFydGluZ0RhdGUiOiIyMDIxLTA4LTExIiwiaW1wZXJzb25hdGVkIjpmYWxzZSwidXVpZCI6IjQ5NTU1YTVlLWE5MGQtNDU4ZS04MGM1LTViMmIyMmYzZWQxNiIsImlhdCI6MTYzMjIzNzQzM30.CAOkUEQU-Mou_HWAITWLzB-RBqmCf3JHRs475CDGnI0';

export const PaymentFlowChapter = () => {
  const { text, event } = useStoryteller();
  const { subscribe, unsubscribe } = useEvent();
  const checkoutUuid = text('checkoutUuid', '357e3785-224c-4d4c-aee9-626bfe3f5012');
  const paymentFlowRef = useRef<PaymentFlowRef>();

  const token = text('token', DEFAULT_TOKEN);

  useEffect(() => {
    const onErrorToast = (payload: GlobalEventEmitterProps) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      event('Error', (payload.error as any)?.payload?.declineCode);
      console.log('Global Error', payload);
    };
    subscribe({ event: 'ERROR' }, onErrorToast);

    return () => {
      unsubscribe({ event: 'ERROR' }, onErrorToast);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLaunch = () => {
    paymentFlowRef.current?.startCheckout(checkoutUuid, (e) => {
      console.log('Checkout flow event', e);
    });
  };

  const onLegacyLaunch = async () => {
    try {
      const client = graphqlClient(token);
      const userSummary = await client.query({
        query: USER_SUMMARY,
        variables: {},
      });
      if (!userSummary.data.orders || userSummary.data.orders.length === 0) {
        console.log('[DEBUG] User has not orders:', userSummary);
        return;
      }
      if (!userSummary.data.orders[0].services || userSummary.data.orders[0].services.length === 0) {
        console.log('[DEBUG] User has not orders with services:', userSummary);
        return;
      }
      if (!userSummary.data.orders[0].services[0].checkout) {
        console.log('[DEBUG] User has not services with checkout:', userSummary);
        return;
      }
      if (
        !userSummary.data.orders[0].services[0].checkout.items ||
        userSummary.data.orders[0].services[0].checkout.items.length === 0
      ) {
        console.log('[DEBUG] User has not a checkout with items:', userSummary);
        return;
      }

      const checkout = {
        orderId: userSummary.data.orders[0].services[0].id,
        items: userSummary.data.orders[0].services[0].checkout.items.map((item: { [key: string]: unknown }) => ({
          reference: item.reference,
        })),
      };
      paymentFlowRef.current?.startLegacyBoxCheckout(checkout, (e) => {
        console.log('Charge flow event', e);
      });
    } catch (err) {
      console.log('[DEBUG] err:', err);
    }
  };

  return (
    <LocaleProvider>
      <SafeAreaView>
        <Container>
          <CheckoutLabel checkoutUuid={checkoutUuid} />
          <LaunchButton onLaunch={onLaunch} />
          <LegacyBoxCheckoutLaunchButton onLaunch={onLegacyLaunch} />

          <PaymentFlow token={token} ref={paymentFlowRef} />
        </Container>
      </SafeAreaView>
    </LocaleProvider>
  );
};

const Container = ({ children }: { children: JSX.Element[] }) => <View style={{ padding: 15 }}>{children}</View>;

const CheckoutLabel = ({ checkoutUuid }: { checkoutUuid: string }) => (
  <Text style={{ marginBottom: 15 }}>{'Checkout UUID: ' + checkoutUuid}</Text>
);

const LaunchButton = ({ onLaunch }: { onLaunch: () => void }) => (
  <Button type={'button'} onPress={onLaunch}>
    {'Launch payment flow'}
  </Button>
);
const LegacyBoxCheckoutLaunchButton = ({ onLaunch }: { onLaunch: () => void }) => (
  <Button marginTop={SIZE.XL} type={'button'} onPress={onLaunch}>
    {'Launch legacy box checkout'}
  </Button>
);

PaymentFlowChapter.displayName = 'Payment Flow';
