import { useEvent } from '@lookiero/event';
import { useEffect, useState } from 'react';

import { AvailableIntegrations, Section } from '../../../../domain/models';
import { mustGetToken } from '../../../../services/ioc';
import { emitGlobalErrorAndMonitor } from '../../../../utils/eventEmitter';
import { availableIntegrationsService } from './AvailableIntegrations.service';

export function useAvailableIntegrations(section: Section) {
  const { publish } = useEvent();
  const [availableIntegrations, setAvailableIntegrations] = useState({} as Record<Section, AvailableIntegrations>);

  useEffect(() => {
    (async () => {
      const result = await availableIntegrationsService.get(section, await mustGetToken(), (err) =>
        emitGlobalErrorAndMonitor(publish, useAvailableIntegrations.name, err),
      );
      setAvailableIntegrations((d) => ({ ...d, [section]: result }));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section]);

  return availableIntegrations[section] || [];
}
