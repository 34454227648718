import { useLocale } from '@lookiero/locale';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { CardUpdater } from '../CardUpdater';
import {
  PaymentInstrumentSelectControllerContext,
  useController,
} from '../PaymentInstrumentSelect/PaymentInstrumentSelect.controller';
import { TEXT } from '../PaymentInstrumentSelect/PaymentInstrumentSelect.definition';
import { PaymentPanel } from '../PaymentPanel';

const CardUpdaterPanel = () => {
  const storeInstance = useContext(PaymentInstrumentSelectControllerContext);
  const [panelVisibility, setShowCardUpdaterPanel] = useController((s) => [
    s.panelVisibility,
    s.setShowCardUpdaterPanel,
  ]);
  const { translate } = useLocale();

  const handleClosePanel = () => setShowCardUpdaterPanel(null);

  return (
    <PaymentPanel
      id="CardUpdaterPanel"
      title={translate(TEXT.PANEL_CARD_TITLE)}
      isVisible={panelVisibility.cardUpdater != null}
      onClose={handleClosePanel}
    >
      <PaymentInstrumentSelectControllerContext.Provider value={storeInstance}>
        {panelVisibility.cardUpdater && <CardUpdater engine={panelVisibility.cardUpdater} />}
      </PaymentInstrumentSelectControllerContext.Provider>
    </PaymentPanel>
  );
};

CardUpdaterPanel.displayName = 'CardUpdaterPanel';
CardUpdaterPanel.propTypes = {
  onClose: PropTypes.func,
};

export { CardUpdaterPanel };
