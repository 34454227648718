import { LocalAdapter, AsyncStorage } from '@lookiero/data-sources';
import create from 'zustand';

import { PaymentMethod } from '../../domain/models';
import { paymentInstrumentRepository } from '../../infrastructure/PaymentInstrumentRepository';
import { getSearchParam } from '../../utils/parseSearchParams';
import { emitTrackingEvent, mustGetToken } from '../ioc';
import { setupLinking } from './payPalFinalizer.linking';

const LAST_FINALIZED_PAYMENT_INSTRUMENT_KEY = 'payments__last_finalized_paypal';

export type PayPalFinalizerResult = { success: boolean };

export type PayPalFinalizerState = {
  finalizing: boolean;
  setFinalizing: (v: boolean) => void;

  resultQueue: PayPalFinalizerResult[];
  addResult: (r: PayPalFinalizerResult) => void;
  consumeResult: (cb: (r: PayPalFinalizerResult) => void) => void;
};

export const usePayPalFinalizerState = create<PayPalFinalizerState>((set, get) => ({
  finalizing: false,
  setFinalizing: (value) => set((s) => ({ ...s, finalizing: value })),

  resultQueue: [],
  addResult: (r) => set((s) => ({ ...s, resultQueue: [...s.resultQueue, r] })),
  consumeResult: (cb) => {
    const resultQueue = get().resultQueue;
    if (resultQueue.length === 0) return;
    resultQueue.forEach((r) => cb(r));
    set((s) => ({ ...s, resultQueue: [] }));
  },
}));

export class PayPalFinalizer {
  private state: PayPalFinalizerState;
  private storage: Promise<CustomStorage>;
  constructor(private mustGetToken: () => Promise<string>) {
    this.state = usePayPalFinalizerState.getState();
    this.storage = new AsyncStorage({ adapter: LocalAdapter });
    setupLinking(queue(this.processUrl.bind(this)));
  }

  private async processUrl(url: string) {
    const paymentInstrumentId = getSearchParam(url, 'lk_payment_instrument_id');
    const redirectStatus = getSearchParam(url, 'redirect_status');

    if (paymentInstrumentId == null) return;
    if ((await this.getLastFinalizedPaymentInstrumentId()) === paymentInstrumentId) return;
    await this.setLastFinalizedPaymentInstrumentId(paymentInstrumentId);

    emitTrackingEvent({
      event: 'paypalReturn',
      paypalReturned: (() => {
        if (redirectStatus === 'succeeded') return 'success';
        if (redirectStatus === 'failed') return 'rejected';
        if (redirectStatus === 'pending') return 'cancelled';
        return 'error';
      })(),
    });

    if (redirectStatus !== 'succeeded') {
      this.state.addResult({ success: false });
      return;
    }

    this.state.setFinalizing(true);
    try {
      await this.finalizePaymentInstrument(paymentInstrumentId);
      emitTrackingEvent({
        event: 'paymentRegistration',
        paymentMethod: PaymentMethod.PAYPAL,
        output: 'success',
      });
      this.state.addResult({ success: true });
    } catch (err) {
      const { code } = err as { code?: number };
      emitTrackingEvent({
        event: 'paymentRegistration',
        paymentMethod: PaymentMethod.PAYPAL,
        output: code != null && code < 500 ? 'rejected' : 'error',
      });
      this.state.addResult({ success: false });
    } finally {
      this.state.setFinalizing(false);
    }
  }

  private async getLastFinalizedPaymentInstrumentId() {
    return (await this.storage).get<string>(LAST_FINALIZED_PAYMENT_INSTRUMENT_KEY);
  }

  private async setLastFinalizedPaymentInstrumentId(paymentInstrumentId: string) {
    await (await this.storage).set(LAST_FINALIZED_PAYMENT_INSTRUMENT_KEY, paymentInstrumentId);
  }

  private async finalizePaymentInstrument(paymentInstrumentId: string) {
    await paymentInstrumentRepository.finalizePayPalPaymentInstrument(paymentInstrumentId, await this.mustGetToken());
  }
}

type CustomStorage = {
  has(key: string): Promise<boolean>;
  get<T>(key: string): Promise<T>;
  set<T>(key: string, data: T): Promise<void>;
};

function queue(cb: (url: string) => Promise<void>): (url: string) => void {
  let previousPromise = Promise.resolve();
  return (url) => (previousPromise = previousPromise.then(() => cb(url)).catch(() => Promise.resolve()));
}

export const payPalFinalizer = new PayPalFinalizer(mustGetToken);
