import { useLocale } from '@lookiero/locale';
import React, { useEffect } from 'react';

import { PaymentsError } from '../../domain/PaymentsError';
import { getMerchantInfo, getStripeKey, GooglePayMerchantInfo, isTestEnv, useFeatureFlags } from '../../services/ioc';
import { getCountryByLocale, getCurrencyByLocale } from '../../services/localization';
import { emitGooglePayAvailability } from './eventEmitter';
import {
  GooglePayErrorCodes,
  GooglePaySheetProps,
  GooglePaySheetResponse,
  StripeCardToken,
  useGooglePayState,
} from './GooglePayState';

type GooglePaySheetWebResponseToken = {
  card: {
    id: string;
    object: string;
    address_city: string | null;
    address_country: string | null;
    address_line1: string | null;
    address_line1_check: string | null;
    address_line2: string | null;
    address_state: string | null;
    address_zip: string | null;
    address_zip_check: string | null;
    brand: string;
    country: string;
    cvc_check: string | null;
    dynamic_last4: string;
    exp_month: number;
    exp_year: number;
    funding: string;
    last4: string;
    metadata: unknown;
    name: string;
    tokenization_method: string;
  };
  client_ip: string;
  created: number;
  id: string;
  livemode: boolean;
  object: string;
  type: string;
  used: boolean;
};

type GooglePaySheetWebError = {
  statusCode: string;
  code: number;
  message: string;
  name: string;
};

const STRIPE_VERSION = '2018-10-31';

const normalizeGooglePaySheetResponseFromWeb = (
  paymentData: google.payments.api.PaymentData,
): GooglePaySheetResponse => {
  const token = JSON.parse(
    paymentData.paymentMethodData.tokenizationData.token,
  ) as unknown as GooglePaySheetWebResponseToken;
  return {
    id: token.id as unknown as StripeCardToken,
    card: {
      brand: token.card.brand,
      expireYear: token.card.exp_year,
      expireMonth: token.card.exp_month,
      last4: token.card.last4,
    },
    error: undefined,
  };
};
const normalizeGooglePaySheetCanceledErrorFromWeb = (error: GooglePaySheetWebError): GooglePaySheetResponse => {
  return {
    id: undefined,
    card: undefined,
    error: {
      code: GooglePayErrorCodes.CANCELED,
      message: error.message,
      stripeErrorCode: error.name,
      declineCode: error.statusCode,
    },
  };
};

function createClient(opts: { merchantInfo: GooglePayMerchantInfo; toAuthorizePayment?: boolean }) {
  return new google.payments.api.PaymentsClient({
    environment: isTestEnv() ? 'TEST' : 'PRODUCTION',
    merchantInfo: opts.merchantInfo,
    paymentDataCallbacks: {
      ...(!opts?.toAuthorizePayment
        ? {}
        : {
            onPaymentAuthorized: () => ({ transactionState: 'SUCCESS' }),
          }),
    },
  });
}

function formatAmount(amount: number): string {
  return (amount / 100).toFixed(2);
}

(window as unknown as { paymentsFront_onGooglePayLoaded: () => void }).paymentsFront_onGooglePayLoaded = async () => {
  useGooglePayState.setState((s) => ({ ...s, loaded: true }));

  const merchantInfo = getMerchantInfo();
  if (!merchantInfo) {
    useGooglePayState.setState((s) => ({ ...s, ready: false, isAvailable: false }));
    return;
  }
  try {
    const client = createClient({ merchantInfo });
    const response = await client.isReadyToPay({
      apiVersion: 2,
      apiVersionMinor: 0,
      allowedPaymentMethods: [
        {
          type: 'CARD',
          parameters: {
            allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
            allowedCardNetworks: ['AMEX', 'DISCOVER', 'JCB', 'MASTERCARD', 'VISA'],
          },
        },
      ],
    });
    if (!response.result) {
      useGooglePayState.setState((s) => ({ ...s, ready: false, isAvailable: false }));
      emitGooglePayAvailability('unavailable');
      return;
    }
    useGooglePayState.setState((s) => ({ ...s, ready: true }));
    emitGooglePayAvailability('available');
  } catch (_err: unknown) {
    useGooglePayState.setState((s) => ({ ...s, ready: false, isAvailable: false }));
    emitGooglePayAvailability('error');
  }
};

const openGooglePaySheet = async (opts: GooglePaySheetProps): Promise<GooglePaySheetResponse> => {
  const merchantInfo = getMerchantInfo();
  if (!merchantInfo)
    throw new PaymentsError({ code: 'google_pay_empty_merchant_info', message: 'Google Pay merchant info is empty' });
  if (!useGooglePayState.getState().loaded)
    throw new PaymentsError({ code: 'google_pay_not_loaded', message: 'Google Pay is not loaded' });
  if (!useGooglePayState.getState().ready)
    throw new PaymentsError({ code: 'google_pay_not_ready', message: 'Google Pay is not ready' });

  const client = createClient({ merchantInfo, toAuthorizePayment: !!opts.toAuthorizePayment });
  const total = opts.toAuthorizePayment?.total || 0;
  try {
    const result = await client.loadPaymentData({
      apiVersion: 2,
      apiVersionMinor: 0,
      allowedPaymentMethods: [
        {
          type: 'CARD',
          parameters: {
            allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
            allowedCardNetworks: ['AMEX', 'DISCOVER', 'JCB', 'MASTERCARD', 'VISA'],
          },
          tokenizationSpecification: {
            type: 'PAYMENT_GATEWAY',
            parameters: {
              gateway: 'stripe',
              'stripe:version': STRIPE_VERSION,
              'stripe:publishableKey': getStripeKey(opts.engine),
            },
          },
        },
      ],
      transactionInfo:
        opts.toAuthorizePayment == null || total == 0
          ? {
              currencyCode: opts.currency,
              totalPriceStatus: 'NOT_CURRENTLY_KNOWN',
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              totalPrice: undefined!,
            }
          : {
              countryCode: opts.country,
              currencyCode: opts.currency,
              totalPriceStatus: 'FINAL',
              totalPrice: formatAmount(total),
              totalPriceLabel: opts.toAuthorizePayment.description,
              displayItems: opts.toAuthorizePayment.items?.map((i) => ({
                label: i.description,
                price: formatAmount(i.amount),
                type: 'LINE_ITEM',
              })),
            },
      merchantInfo,
      callbackIntents: opts.toAuthorizePayment ? ['PAYMENT_AUTHORIZATION'] : [],
    });
    return normalizeGooglePaySheetResponseFromWeb(result);
  } catch (err) {
    if ((err as GooglePaySheetWebError).statusCode === 'CANCELED') {
      return normalizeGooglePaySheetCanceledErrorFromWeb(err as GooglePaySheetWebError);
    }
    throw new PaymentsError(err);
  }
};

export const GooglePay = () => {
  const { locale } = useLocale();

  const country = getCountryByLocale(locale);
  const currency = getCurrencyByLocale(locale);
  const isGooglePayFeatureFlagEnabled = !!useFeatureFlags()[`ALLOW_GOOGLE_PAY_${country}`];

  useEffect(() => {
    useGooglePayState.setState((s) => ({
      ...s,
      openGooglePaySheet,
      country,
      currency,
      isAvailable: s.ready && isGooglePayFeatureFlagEnabled,
    }));
  }, [country, currency, isGooglePayFeatureFlagEnabled]);

  return <></>;
};
