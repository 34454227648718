import { DelayedTokenizationStatus, Engine, PaymentMethod } from '../../../domain/models';
import { PaymentsError } from '../../../domain/PaymentsError';
import { invalidatePaymentInstrument } from '../../../hooks/usePaymentInstrument';
import { paymentInstrumentRepository } from '../../../infrastructure';
import { mustGetToken } from '../../../services/ioc';
import { usePayPalFinalizerState } from '../../../services/PayPalFinalizer';
import { GlobalEventEmitter } from '../../../utils/eventEmitter';
import { isWeb } from '../../../utils/platform';
import { GooglePayErrorCodes, loadingModalCanBeDisplayedInTheGooglePayFlow, useGooglePayState } from '../../GooglePay';
import { openURL } from '../internals/linking';
import { PaymentInstrumentSelectController } from '../PaymentInstrumentSelect.controller';

export type AccordionHandlerType = {
  controller: PaymentInstrumentSelectController;
  engine: Engine;
  priceForTokenization?: number;
  publish?: GlobalEventEmitter;
};

export const cardAccordionHandler = ({ controller, engine }: AccordionHandlerType) => {
  controller.setShowCardUpdaterPanel(engine);
};

export const googlePayAccordionHandler = async ({
  controller,
  engine,
  priceForTokenization,
  publish,
}: AccordionHandlerType) => {
  if (!publish) {
    throw new PaymentsError(new Error('Publish function is required in Google Pay accordion handler'));
  }
  try {
    loadingModalCanBeDisplayedInTheGooglePayFlow() && controller.showLoadingModal();
    controller.setPaymentInstrumentLoading(true);
    const res = await useGooglePayState.getState().openGooglePaySheet({
      country: useGooglePayState.getState().country,
      currency: useGooglePayState.getState().currency,
      engine,
      toAuthorizePayment: { total: priceForTokenization || 0 },
    });

    if (res.error) {
      controller.setPaymentInstrumentLoading(false);
      throw new PaymentsError(res.error);
    }

    controller.setShowPaymentInstrumentSelectPanel(false);
    if (isWeb) {
      await paymentInstrumentRepository.changePaymentInstrumentByCardToken(
        engine,
        PaymentMethod.GOOGLE_PAY,
        res.id,
        await mustGetToken(),
      );
    } else {
      await paymentInstrumentRepository.changePaymentInstrumentByPaymentMethodId(
        engine,
        PaymentMethod.GOOGLE_PAY,
        res.id,
        await mustGetToken(),
      );
    }
    await invalidatePaymentInstrument();
  } catch (err) {
    if (err instanceof PaymentsError && err.code === GooglePayErrorCodes.CANCELED) {
      throw new PaymentsError({ code: DelayedTokenizationStatus.CANCELED });
    }
    throw new PaymentsError(err);
  } finally {
    loadingModalCanBeDisplayedInTheGooglePayFlow() && controller.hideLoadingModal();
    controller.setPaymentInstrumentLoading(false);
  }
};

export const paypalAccordionHandler = async ({ controller, engine }: AccordionHandlerType) => {
  if (engine == null) return;
  if (controller.isRedirecting || usePayPalFinalizerState.getState().finalizing || controller.paymentInstrumentLoading)
    return;

  controller.setIsRedirecting(true);
  controller.showPayPalRedirectionModal();

  if (controller.beforeRedirectCallback == null) {
    throw new Error('PaymentInstrumentSelect property beforeRedirect was not defined');
  }

  const returnUrl = await controller.beforeRedirectCallback();
  if (returnUrl == null) {
    throw new Error('PaymentInstrumentSelect beforeRedirect implementation returned null');
  }

  const result = await paymentInstrumentRepository.createPayPalPaymentInstrument(
    returnUrl,
    await mustGetToken(),
    engine,
  );
  openURL(result.redirectionUrl);
  if (!isWeb) {
    setTimeout(() => {
      controller.setIsRedirecting(false);
      controller.hideAllPanels();
    }, 1000);
  }
};
