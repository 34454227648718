/* eslint-disable no-console */
import { PortalProvider, Button, Text, SafeAreaView, ALIGN, LAYOUT } from '@lookiero/aurora';
import { useEvent } from '@lookiero/event';
import { useStoryteller } from '@lookiero/storyteller';
import React, { useEffect, useRef, useState } from 'react';

import { PaymentInstrumentSelect, PaymentInstrumentSelectRef } from '../components';
import { DelayedTokenizationStatus, PaymentMethod, Section } from '../domain/models';
import { PaymentsError } from '../domain/PaymentsError';
import { GlobalEventEmitterProps } from '../utils/eventEmitter';
import { LocaleProvider } from './helpers';

export const PaymentInstrumentSelectChapter = () => {
  const { boolean, select } = useStoryteller();
  const { subscribe, unsubscribe } = useEvent();
  const [isSelectedToDelayTokenization, setIsSelectedToDelayTokenization] = useState(false);

  const paymentInstrumentSelectRef = useRef<PaymentInstrumentSelectRef>();

  useEffect(() => {
    const onErrorToast = (error: PaymentsError) => console.log('onError', error);
    const onMonitoringErrorToast = (error: PaymentsError) => console.log('[DEBUG] onMonitoringError', error);
    const onSuccessToast = (payload: GlobalEventEmitterProps) => {
      setIsSelectedToDelayTokenization(false);
      return console.log('onSuccess', payload);
    };
    const onSelectToDelayTokenization = (payload: GlobalEventEmitterProps) => {
      setIsSelectedToDelayTokenization(!!payload.context.selected);
      return console.log('onSelectToDelayTokenization', payload);
    };

    subscribe({ event: 'ERROR' }, onErrorToast);
    subscribe({ domain: 'PAYMENTS', event: 'MONITORING.ERROR' }, onMonitoringErrorToast);
    subscribe({ event: 'PAYMENT_INSTRUMENT_UPDATED' }, onSuccessToast);
    subscribe({ event: 'PAYMENT_INSTRUMENT_SELECTED_TO_DELAYED_TOKENIZATION' }, onSelectToDelayTokenization);

    return () => {
      unsubscribe({ event: 'ERROR' }, onErrorToast);
      unsubscribe({ domain: 'PAYMENTS', event: 'MONITORING.ERROR' }, onMonitoringErrorToast);
      unsubscribe({ event: 'PAYMENT_INSTRUMENT_UPDATED' }, onSuccessToast);
      unsubscribe({ event: 'PAYMENT_INSTRUMENT_SELECTED_TO_DELAYED_TOKENIZATION' }, onSelectToDelayTokenization);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hidePaymentMethods = Object.values(PaymentMethod)
    .map((paymentMethod) => ({
      paymentMethod,
      hidden: boolean(`Hide PM: ${paymentMethod}`, false),
    }))
    .filter((d) => d.hidden)
    .map((d) => d.paymentMethod);

  const returnUrl = window.location ? window.location.href : 'lookiero-payments://home';
  return (
    <LocaleProvider>
      <PortalProvider>
        <PaymentInstrumentSelect
          allowDelayedTokenization={boolean('allowDelayedTokenization', false)}
          ref={paymentInstrumentSelectRef}
          section={select('section', Section.BOX_PLACE_ORDER, { options: Object.values(Section) })}
          hasError={boolean('hasError', false)}
          hidePaymentMethods={hidePaymentMethods}
          priceForTokenization={1050}
          beforeRedirect={() => Promise.resolve(returnUrl)}
        />

        {isSelectedToDelayTokenization && (
          <SafeAreaView style={{ flex: 1 }} marginTop={LAYOUT.L}>
            <Text level={2} align={ALIGN.CENTER}>
              Google Pay is select for delayed tokenization
            </Text>
            <Button
              marginTop={LAYOUT.XS}
              type={'button'}
              onPress={async () => {
                try {
                  if (paymentInstrumentSelectRef.current?.runDelayedTokenization) {
                    await paymentInstrumentSelectRef.current.runDelayedTokenization();
                  }
                } catch (err) {
                  if (err instanceof PaymentsError && err.code === DelayedTokenizationStatus.CANCELED) return;
                  console.log('[DEBUG] err', err);
                }
              }}
            >
              {'Tokenize Google Pay'}
            </Button>
          </SafeAreaView>
        )}
      </PortalProvider>
    </LocaleProvider>
  );
};

PaymentInstrumentSelectChapter.displayName = 'Payment Instrument Select';
