import { ALIGN, DISPLAY, SIZE, Modal, Portal, Spinner, View, useDevice } from '@lookiero/aurora';
import React from 'react';

import { useAnimatedVisibility } from '../../hooks/useAnimatedVisibility';
import { useRandomString } from '../../utils/randomString';
import { useController } from '../PaymentInstrumentSelect/PaymentInstrumentSelect.controller';

export const LoadingModal = () => {
  const { screen } = useDevice();
  const panelVisibility = useController((s) => s.panelVisibility);
  const { isRendered, isVisible } = useAnimatedVisibility(panelVisibility.loadingModal);
  const randomId = useRandomString(5);

  if (!isRendered) return <></>;
  return (
    <Portal id={'loading-modal-' + randomId}>
      <Modal fullHeight={screen.S} isVisible={isVisible} size={{ M: '1/3', L: '1/3' }}>
        <View
          alignItems={ALIGN.CENTER}
          justifyContent={ALIGN.CENTER}
          display={DISPLAY.FLEX}
          paddingBottom={SIZE.M}
          style={{ flex: 1 }}
        >
          <Spinner />
        </View>
      </Modal>
    </Portal>
  );
};
