import ApolloClient, { InMemoryCache } from 'apollo-boost';

import { Checkout } from '../../domain/models';
import { CHECKOUT_ORDER } from '../graphql/mutations/checkout';

export class LegacyBoxCheckoutRepository {
  constructor(private client: ApolloClient<InMemoryCache>) {}

  async performCheckout(checkout: Checkout) {
    return await this.client.mutate({
      mutation: CHECKOUT_ORDER,
      variables: { checkout },
    });
  }
}
