import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import create, { StateSelector } from 'zustand';

import { AvailableIntegrations, Engine, PaymentMethod, PendingPaymentInstrument, Section } from '../../domain/models';

export type PaymentInstrumentSelectControllerFactoryParams = {
  allowDelayedTokenization: boolean;
  availableIntegrations: AvailableIntegrations;
  hasError: boolean;
  hidePaymentMethods: PaymentMethod[];
  section: Section;
  beforeRedirectCallback?: () => Promise<string>;
};

export type PaymentInstrumentSelectController = PaymentInstrumentSelectControllerFactoryParams & {
  panelVisibility: {
    cardUpdater: Engine | null;
    paymentInstrumentSelect: boolean;
    paypalRedirectionModal: boolean;
    loadingModal: boolean;
  };
  isRedirecting: boolean;
  pendingPaymentInstrument: PendingPaymentInstrument | null;
  paymentInstrumentLoading: boolean;

  setPaymentInstrumentLoading: (paymentInstrumentLoading: boolean) => void;
  setShowCardUpdaterPanel: (engine: Engine | null) => void;
  setShowPaymentInstrumentSelectPanel: (visible: boolean) => void;
  setIsRedirecting: (redirecting: boolean) => void;
  hideAllPanels: () => void;
  showPayPalRedirectionModal: () => void;
  hidePayPalRedirectionModal: () => void;
  showLoadingModal: () => void;
  hideLoadingModal: () => void;
  selectPaymentMethodToDelayedTokenization: (paymentMethod: PaymentMethod, engine: Engine) => void;
  getPendingPaymentInstrument: () => PendingPaymentInstrument | null;
  removePendingPaymentInstrument: () => void;
};

const SKELETON_TIMEOUT = 500;
export const controllerFactory = (params: PaymentInstrumentSelectControllerFactoryParams) =>
  create<PaymentInstrumentSelectController>((set, get) => ({
    ...params,
    panelVisibility: {
      cardUpdater: null,
      paymentInstrumentSelect: false,
      paypalRedirectionModal: false,
      loadingModal: false,
    },
    isRedirecting: false,
    pendingPaymentInstrument: null,

    setPaymentInstrumentLoading: (paymentInstrumentLoading) =>
      set((s) => ({ ...s, paymentInstrumentLoading: paymentInstrumentLoading })),
    setShowCardUpdaterPanel: (engine: Engine | null) =>
      set((s) => ({ ...s, panelVisibility: { ...s.panelVisibility, cardUpdater: engine } })),
    setShowPaymentInstrumentSelectPanel: (visible: boolean) =>
      set((s) => ({
        ...s,
        panelVisibility: { ...s.panelVisibility, paymentInstrumentSelect: visible },
      })),
    setIsRedirecting: (redirecting: boolean) => set((s) => ({ ...s, isRedirecting: redirecting })),
    hideAllPanels: () =>
      set((s) => ({
        ...s,
        panelVisibility: {
          cardUpdater: null,
          paymentInstrumentSelect: false,
          paypalRedirectionModal: false,
          loadingModal: false,
        },
      })),
    showPayPalRedirectionModal: () =>
      set((s) => ({
        ...s,
        panelVisibility: {
          cardUpdater: null,
          paymentInstrumentSelect: false,
          paypalRedirectionModal: true,
          loadingModal: false,
        },
      })),
    hidePayPalRedirectionModal: () =>
      set((s) => ({ ...s, panelVisibility: { ...s.panelVisibility, paypalRedirectionModal: false } })),
    showLoadingModal: () => set((s) => ({ ...s, panelVisibility: { ...s.panelVisibility, loadingModal: true } })),
    hideLoadingModal: () => set((s) => ({ ...s, panelVisibility: { ...s.panelVisibility, loadingModal: false } })),
    selectPaymentMethodToDelayedTokenization: (paymentMethod, engine) => {
      set((s) => ({
        ...s,
        paymentInstrumentLoading: true,
        pendingPaymentInstrument: { engine, payment_method: paymentMethod },
      }));
      setTimeout(() => set((s) => ({ ...s, paymentInstrumentLoading: false })), SKELETON_TIMEOUT);
    },
    paymentInstrumentLoading: false,
    getPendingPaymentInstrument: () => get().pendingPaymentInstrument,
    removePendingPaymentInstrument: () =>
      set((s) => ({ ...s, pendingPaymentInstrument: null, paymentInstrumentLoading: false })),
  }));

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const PaymentInstrumentSelectControllerContext = createContext<ReturnType<typeof controllerFactory>>(null!);

export const useControllerFactory = (params: PaymentInstrumentSelectControllerFactoryParams) => {
  const [useControllerInstance] = useState(() => controllerFactory(params));

  useEffect(() => {
    const state = useControllerInstance.getState();
    if (
      params.section === state.section &&
      params.hasError === state.hasError &&
      params.hidePaymentMethods.join(',') === state.hidePaymentMethods.join(',') &&
      params.beforeRedirectCallback === state.beforeRedirectCallback &&
      JSON.stringify(params.availableIntegrations) === JSON.stringify(state.availableIntegrations)
    )
      return;

    useControllerInstance.setState((s) => ({
      ...s,
      ...params,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    params.section,
    params.hasError,
    params.hidePaymentMethods,
    params.beforeRedirectCallback,
    params.availableIntegrations,
  ]);

  return useCallback(({ children }: { children?: ReactNode }) => {
    return (
      <PaymentInstrumentSelectControllerContext.Provider value={useControllerInstance}>
        {children}
      </PaymentInstrumentSelectControllerContext.Provider>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export function useController<T>(selector: StateSelector<PaymentInstrumentSelectController, T>): T {
  const useControllerInstance = useContext(PaymentInstrumentSelectControllerContext);
  return useControllerInstance(selector);
}
