import { useEvent } from '@lookiero/event';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { PaymentInstrument } from '../../../domain/models';
import { PaymentsError } from '../../../domain/PaymentsError';
import { getToken, mustGetToken } from '../../../services/ioc';
import { emitMonitoringError } from '../../../utils/eventEmitter';
import { parseUserToken } from '../../../utils/parseUserToken';
import { paymentInstrumentRepository } from '../../PaymentInstrumentRepository';
import { queryProviderContext } from '../TanStackQueryProvider/TanStackQueryProvider';

const TTL_IN_MILLIS = 15000 * 60; // 15 minutes

export const getQueryId = (uuid: string | undefined): Array<string> => {
  const queryId = ['paymentInstrument'];
  if (!uuid) {
    return queryId;
  }
  return queryId.concat(uuid);
};

export const usePaymentInstrumentQuery = () => {
  const [token, setToken] = useState<string>();
  const [uuid, setUuid] = useState<string>();
  const { publish } = useEvent();
  const queryClient = useQueryClient({ context: queryProviderContext });

  useEffect(() => {
    (async () => {
      const userToken = await getToken();
      if (userToken) {
        setToken(userToken);
        setUuid(parseUserToken(userToken).uuid);
      }
    })();
  }, []);

  const getPaymentInstrument = async (): Promise<PaymentInstrument | null> => {
    try {
      if (!token) return null;
      return (await paymentInstrumentRepository.getPaymentInstrumentInfo(token)) || null;
    } catch (error) {
      const paymentsError = new PaymentsError(error);
      if (paymentsError.code === '500' && paymentsError.message.includes('Unauthorized')) return null;
      emitMonitoringError(publish, usePaymentInstrumentQuery.name, error);
      return null;
    }
  };

  const { data, isLoading } = useQuery(getQueryId(uuid), getPaymentInstrument, {
    context: queryProviderContext,
    refetchInterval: TTL_IN_MILLIS,
    staleTime: TTL_IN_MILLIS,
    refetchOnReconnect: true,
    cacheTime: TTL_IN_MILLIS,
  });

  return {
    paymentInstrument: data || null,
    loading: isLoading,
    refresh: async () => {
      const uuid = parseUserToken(await mustGetToken()).uuid;
      await queryClient.invalidateQueries(getQueryId(uuid));
    },
  };
};
