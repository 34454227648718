import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import PropTypes from 'prop-types';
import React from 'react';

let stripePromise: ReturnType<typeof loadStripe> | null;

type StripeProviderProps = {
  children: React.ReactNode | React.ReactNode[];
  options: StripeElementsOptions;
  publishableKey: string;
};

const StripeProvider = ({ children, options, publishableKey }: StripeProviderProps) => {
  if (stripePromise == null) {
    stripePromise = loadStripe(publishableKey);
  }
  return (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  );
};

StripeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  options: PropTypes.object,
};

export { StripeProvider };
