import { Engine } from '../../domain/models';
import { paymentInstrumentRepository } from '../../infrastructure';
import { mustGetToken } from '../../services/ioc';

const getEngine = async (): Promise<Engine | undefined> => {
  const availableIntegrations = await paymentInstrumentRepository.getAvailableIntegrations('', await mustGetToken());
  return availableIntegrations[0]?.engine;
};

export { getEngine };
