import { getMessageForDeclineCode } from '../../../../domain/declineCodes';
import { CheckoutStatus } from '../../../../domain/models';
import { ChargeStatus, CheckoutAPI } from '../../../../infrastructure/CheckoutAPI';
import assertUnreachable from '../../../../utils/assertUnreachable';
import { PaymentFlowStrategy } from '../PaymentFlowStrategy';

const TIMEOUT_MARGIN_IN_SECONDS = 15;

export type CheckoutEvent = {
  status: CheckoutStatus;
  toaster: { id: string } | null;
  final: boolean;
};

export class CheckoutStrategy extends PaymentFlowStrategy<
  string,
  CheckoutEvent,
  {
    checkoutAPI: CheckoutAPI;
  }
> {
  public async start(checkoutUuid: string) {
    try {
      const reconciliationResult = await this.reconcileCheckout(checkoutUuid);

      if (reconciliationResult.status !== CheckoutStatus.REQUIRES_ACTION) {
        return this.emit(reconciliationResult.status, {
          final: true,
          toaster: getMessageForDeclineCode(reconciliationResult?.declineCode),
        });
      }

      this.emit(CheckoutStatus.REQUIRES_ACTION);
      const challengeResult = await this.ui.displayChallenge(
        reconciliationResult.challengeUrl!,
        reconciliationResult.challengeTimeout * 60 - TIMEOUT_MARGIN_IN_SECONDS,
      );

      if (challengeResult === 'cancelled') {
        this.cancelChargeInBackground(reconciliationResult.chargeId!, checkoutUuid);
        return this.emit(CheckoutStatus.REQUIRED_ACTION_CANCELLED, { final: true });
      }

      if (challengeResult === 'timed_out') {
        return this.emit(CheckoutStatus.REQUIRED_ACTION_CANCELLED, {
          final: true,
          toaster: { id: 'payment.error.timeout2fa' },
        });
      }

      if (challengeResult === 'finished') {
        const confirmationResult = await this.confirmCharge(reconciliationResult.chargeId!);
        return this.emit(confirmationResult.status, {
          final: true,
          toaster: getMessageForDeclineCode(confirmationResult?.declineCode),
        });
      }

      assertUnreachable(challengeResult);
    } catch (err) {
      this.resetState();
      this.notifyUnexpectedError(err as Error, { checkoutUuid });
      this.emit(CheckoutStatus.ERROR, { final: true });
    }
  }

  private async reconcileCheckout(checkoutUuid: string): Promise<ReconcileCheckoutResult> {
    const result = await this.ui.loadingWhile(this.deps.checkoutAPI.reconcile(checkoutUuid));
    return {
      status: result.checkout.status,
      chargeId: result.charge?.chargeId,
      declineCode: result.charge?.status === ChargeStatus.REJECTED ? result.charge.declineCode : undefined,
      challengeUrl:
        result.checkout.status === CheckoutStatus.REQUIRES_ACTION && result.charge?.metadata.redirectToUrl_url
          ? result.charge.metadata.redirectToUrl_url
          : undefined,
      challengeTimeout: result.checkout.timeout,
    };
  }

  private async confirmCharge(chargeId: string): Promise<ConfirmChargeResult> {
    const result = await this.ui.challengeLoadingWhile(this.deps.checkoutAPI.confirmCharge(chargeId));
    return {
      status: result.checkout.status,
      declineCode: result.charge?.status === ChargeStatus.REJECTED ? result.charge.declineCode : undefined,
    };
  }

  private async cancelChargeInBackground(chargeId: string, checkoutUuid: string) {
    try {
      await this.deps.checkoutAPI.cancelCharge(chargeId, 'cancelled by user');
    } catch (err) {
      this.notifyUnexpectedError(err as Error, { checkoutUuid });
    }
  }

  private emit(
    status: CheckoutEvent['status'],
    extra?: {
      final?: CheckoutEvent['final'];
      toaster?: CheckoutEvent['toaster'];
    },
  ) {
    this.emitter({
      status,
      final: extra?.final || false,
      toaster: extra?.toaster || null,
    });
  }
}

type ReconcileCheckoutResult = {
  status: CheckoutStatus;
  declineCode?: string;
  chargeId?: string;
  challengeUrl?: string;
  challengeTimeout: number;
};

type ConfirmChargeResult = {
  status: CheckoutStatus;
  declineCode?: string;
};
