const ERROR_PAYMENT_CARD_DEFAULT = { id: 'payment.error.paragraph_card_standard' };

const MESSAGES_FOR_DECLINE_CODES: { [key: string]: { id: string } } = {
  authentication_acc_number_issue: ERROR_PAYMENT_CARD_DEFAULT,
  authentication_address_issue: ERROR_PAYMENT_CARD_DEFAULT,
  authentication_cvc_issue: { id: 'payment.error.paragraph_card_cvc' },
  authentication_expiration_date_issue: { id: 'payment.error.paragraph_card_expired_date' },
  authentication_pin_issue: ERROR_PAYMENT_CARD_DEFAULT,
  critical_issue: ERROR_PAYMENT_CARD_DEFAULT,
  duplicate_transaction_issue: { id: 'payment.error.paragraph_card_refresh' },
  method_expired_issue: { id: 'payment.error.paragraph_card_expired_date' },
  method_funds_issue: { id: 'payment.error.paragraph_card_insufficient_funds' },
  pending_payment_pay1: { id: 'payment.error.paragraph_pending_pay1' },
  pending_payment_pay2: { id: 'payment.error.paragraph_pending_pay2' },
  provider_currency_issue: { id: 'payment.error.paragraph_card_invalid_currency' },
  provider_issue: ERROR_PAYMENT_CARD_DEFAULT,
  provider_limit_issue: { id: 'payment.error.paragraph_card_exceeded_limit' },
  provider_pin_entries_issue: ERROR_PAYMENT_CARD_DEFAULT,
  provider_purchasetype_issue: ERROR_PAYMENT_CARD_DEFAULT,
  standard_issue: ERROR_PAYMENT_CARD_DEFAULT,
  test_issue: { id: 'payment.error.paragraph_card_test' },
};

export function getMessageForDeclineCode(declineCode?: string): { id: string } | null {
  if (declineCode == null) return null;
  return MESSAGES_FOR_DECLINE_CODES[declineCode] || ERROR_PAYMENT_CARD_DEFAULT;
}
