import { useState } from 'react';

const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
export const randomString = (length: number) =>
  new Array(length)
    .fill(null)
    .map(() => CHARS.charAt(Math.floor(Math.random() * CHARS.length)))
    .join('');

export const useRandomString = (length: number) => useState(() => randomString(length))[0];
