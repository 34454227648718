import { PaymentInstrument } from '../domain/models';
import {
  getQueryId,
  usePaymentInstrumentQuery,
} from '../infrastructure/tanStack/paymentInstrumentQuery/usePaymentInstrumentQuery';
import { queryClient } from '../infrastructure/tanStack/TanStackQueryProvider';
import { getToken } from '../services/ioc';
import { parseUserToken } from '../utils/parseUserToken';

export const usePaymentInstrument = () => {
  const paymentInstrumentQuery = usePaymentInstrumentQuery();

  return {
    info: paymentInstrumentQuery.paymentInstrument,
    loading: paymentInstrumentQuery.loading,
    refresh: async () => {
      await paymentInstrumentQuery.refresh();
    },
  };
};

export const getPaymentInstrument = async (): Promise<PaymentInstrument | null> => {
  const token = await getToken();
  if (!token) return null;
  return queryClient.getQueryData(getQueryId(parseUserToken(token).uuid)) || null;
};

export const invalidatePaymentInstrument = async () => {
  const token = await getToken();
  if (!token) return null;
  queryClient.invalidateQueries(getQueryId(parseUserToken(token).uuid));
};
