import { SafeAreaView } from '@lookiero/aurora';
import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { PaymentInstrumentLogo, findLogo, findFallbackLogo } from '../components/PaymentInstrumentLogo';
import { PaymentInstrumentBrand, PaymentInstrumentNetwork, ImageSizes } from '../domain/models';

export const PaymentInstrumentLogoChapter = () => {
  const { select } = useStoryteller();

  return (
    <SafeAreaView>
      <PaymentInstrumentLogo
        logoName={findLogo(
          select('paymentInstrumentBrand', PaymentInstrumentBrand.VISA, {
            options: Object.values(PaymentInstrumentBrand),
          }),
          [
            select('paymentInstrumentNetwork', '', {
              options: ['', ...Object.values(PaymentInstrumentNetwork)],
            }),
          ],
        )}
        fallbackLogoName={findFallbackLogo(
          select('paymentInstrumentBrand', PaymentInstrumentBrand.VISA, {
            options: Object.values(PaymentInstrumentBrand),
          }),
        )}
        size={select('size', ImageSizes.S, { options: Object.values(ImageSizes) })}
      />
    </SafeAreaView>
  );
};

PaymentInstrumentLogoChapter.displayName = 'Payment Instrument Logo';
