import React, { useEffect, useState } from 'react';

import { Engine } from '../../domain/models';
import { usePaymentInstrument } from '../../hooks/usePaymentInstrument';
import { GooglePayLoader } from '../GooglePay/GooglePayLoader';
import { getEngine } from './PaymentsFrontLoader.controller';

export const PaymentsFrontLoader = () => {
  return <PaymentsFrontLoaderComposer />;
};

const PaymentsFrontLoaderComposer = () => {
  usePaymentInstrument();
  const [engine, setEngine] = useState<Engine>();

  useEffect(() => {
    let isMounted = true;
    const getPaymentsEngine = async () => {
      const obtainedEngine = await getEngine();
      if (isMounted) {
        setEngine(obtainedEngine);
      }
    };
    getPaymentsEngine();

    return () => {
      isMounted = false;
    };
  }, []);

  if (!engine) return <></>;
  return (
    <>
      <GooglePayLoader engine={engine} />
    </>
  );
};
