import { request } from '@lookiero/data-sources';

import { AvailableIntegrations, Engine, PaymentInstrument, PaymentMethod } from '../domain/models';
import { getHeaders } from './headers';

export interface ChangePaymentInstrument {
  result: boolean;
  paymentEngine: string;
  errors: {
    code: string;
  }[];
  metadata: Record<string, object | string>;
  paymentInstrumentId: string;
}

export class PaymentInstrumentRepository {
  async changePaymentInstrumentByPaymentMethodId(
    engine: Engine,
    paymentMethod: PaymentMethod,
    paymentMethodId: string,
    token: string,
  ): Promise<ChangePaymentInstrument> {
    return await request({
      endpoint: '/web/api/payments/v1/payment-instrument/',
      method: 'POST',
      headers: getHeaders(token),
      paymentEngine: engine,
      metadata: { paymentMethodId, paymentMethod },
    });
  }

  async changePaymentInstrumentByCardToken(
    engine: Engine,
    paymentMethod: PaymentMethod,
    cardToken: string,
    token: string,
  ): Promise<ChangePaymentInstrument> {
    return await request({
      endpoint: '/web/api/payments/v1/payment-instrument/',
      method: 'POST',
      headers: getHeaders(token),
      paymentEngine: engine,
      metadata: { cardToken, paymentMethod },
    });
  }

  async getAvailableIntegrations(section: string, token: string): Promise<AvailableIntegrations> {
    return await request({
      endpoint: '/web/api/payments/v1/available-integrations',
      method: 'POST',
      headers: getHeaders(token),
      section,
    });
  }

  async getPaymentInstrumentInfo(token: string): Promise<PaymentInstrument | null> {
    return await request({
      endpoint: '/web/api/payment-instruments/v2',
      method: 'GET',
      headers: getHeaders(token),
    });
  }

  async createPayPalPaymentInstrument(
    returnUrl: string,
    token: string,
    engine: Engine,
  ): Promise<{ redirectionUrl: string }> {
    return await request({
      endpoint: '/web/api/payments/v1/paypal/payment-instrument/create',
      method: 'POST',
      headers: getHeaders(token),
      returnUrl,
      paymentEngine: engine,
    });
  }

  async finalizePayPalPaymentInstrument(paymentInstrumentId: string, token: string): Promise<void> {
    return await request({
      endpoint: '/web/api/payments/v1/paypal/payment-instrument/finalize',
      method: 'POST',
      headers: getHeaders(token),
      paymentInstrumentId,
    });
  }
}

export const paymentInstrumentRepository = new PaymentInstrumentRepository();
