import { SafeAreaView } from '@lookiero/aurora';
import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { PaymentAccordion } from '../components/PaymentAccordion';
import { PaymentInstrumentBrand } from '../domain/models';
import { Children } from './helpers';

export const PaymentAccordionChapter = () => {
  const { select } = useStoryteller();

  return (
    <SafeAreaView>
      <PaymentAccordion
        paymentInstrumentBrand={select('paymentInstrumentBrand', PaymentInstrumentBrand.CARD, {
          options: Object.values(PaymentInstrumentBrand),
        })}
        testID={''}
      >
        <Children text={'Payment Accordion children'} />
      </PaymentAccordion>
    </SafeAreaView>
  );
};

PaymentAccordionChapter.displayName = 'Payment Accordion';
