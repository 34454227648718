import { SafeAreaView } from '@lookiero/aurora';
import React from 'react';

import { Label } from '../components/Label';

export const LabelChapter = () => (
  <SafeAreaView>
    <Label level={1}>label</Label>
  </SafeAreaView>
);

LabelChapter.displayName = 'Label';
