import { Engine, PaymentMethod } from '../../../../domain/models';
import { useController } from '../../PaymentInstrumentSelect.controller';

export default (): Record<PaymentMethod, Engine | null> => {
  const [hidePaymentMethods, availableIntegrations] = useController((s) => [s.hidePaymentMethods, s.availableIntegrations]);

  const getPaymentMethodAvailableEngine = (paymentMethod: PaymentMethod) => {
    if (paymentMethodIsHidden(paymentMethod)) return null;
    return (
      availableIntegrations.filter((integration) =>
        integration.availableMethods.some((availableMethod) => availableMethod === paymentMethod),
      )[0]?.engine || null
    );
  };

  const paymentMethodIsHidden = (paymentMethod: PaymentMethod) => hidePaymentMethods.indexOf(paymentMethod) >= 0;

  return {
    [PaymentMethod.CARD]: getPaymentMethodAvailableEngine(PaymentMethod.CARD),
    [PaymentMethod.GOOGLE_PAY]: getPaymentMethodAvailableEngine(PaymentMethod.GOOGLE_PAY),
    [PaymentMethod.PAYPAL]: getPaymentMethodAvailableEngine(PaymentMethod.PAYPAL),
  };
};
