/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';

import { WebViewParams } from './WebView.types';

export const WebView = ({ url, onLoad, onMessage, style }: WebViewParams) => {
  useEffect(() => {
    if (url) window.addEventListener('message', onMessage);
    return () => window.removeEventListener('message', onMessage);
  }, [url, onMessage]);

  return <iframe style={style} src={url} frameBorder="0" width="100%" onLoad={onLoad} />;
};
